import React, { createContext, useReducer, ReactNode, useContext } from "react";
import { CouponData, FormattedCartItem } from "../models/cart";
import { Product } from "../models/products/products.types";
import { Discount } from "../models/checkout/checkout.types";

export type AppState = {
  cartItems: FormattedCartItem[];
  products: Product[];
  coupons: CouponData[];
  userId: null | number;
  displayLoading: boolean;
  loadingMessage: null | string;
  isLoadingCart: boolean;
  discounts: Discount[];
};

export type Action =
  | {
      type: "UPDATE_CART";
      payload: { cartItems: FormattedCartItem[] };
    }
  | {
      type: "UPDATE_COUPONS";
      payload: { coupons: CouponData[] };
    }
  | { type: "SET_PRODUCTS"; payload: Product[] }
  | {
      type: "IS_LOADING_CART";
      payload: boolean;
    }
  | {
      type: "SET_LOADING";
      payload: { isLoading: boolean; loadingMessage?: string };
    }
  | { type: "SET_USER"; payload: number }
  | { type: "UPDATE_DISCOUNTS"; payload: { discounts: Discount[] } };

type AppContextType = {
  state: AppState;
  dispatch: React.Dispatch<Action>;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

const initialState: AppState = {
  cartItems: [],
  products: [],
  coupons: [],
  userId: null,
  loadingMessage: null,
  displayLoading: false,
  isLoadingCart: true,
  discounts: [],
};

const reducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case "UPDATE_CART":
      return {
        ...state,
        cartItems: action.payload.cartItems,
      };
    case "UPDATE_COUPONS":
      return {
        ...state,
        coupons: action.payload.coupons ?? [],
      };
    case "UPDATE_DISCOUNTS":
      return {
        ...state,
        discounts: action.payload.discounts ?? [],
      };
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        userId: action.payload,
      };
    case "IS_LOADING_CART":
      return {
        ...state,
        isLoadingCart: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loadingMessage: action.payload.loadingMessage ?? null,
        displayLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
};

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a StoreProvider");
  }

  return context;
};

export { AppProvider, useAppContext };
