import { useMemo } from "react";
import { FormattedCartItem } from "../../../../models/cart";
import { Product } from "../../../../models/products/products.types";

interface MenuItem {
  id: number;
  title: string;
  imageUrl: string;
  quantity: number;
  price: number;
  key: string;
  productCategories: string[];
}

// interface DeleteItemProps {
//   productId: number;
//   cartItemKey: string;
//   productCategories: string[];
// }

interface ItemImageProps {
  productImageUrl: string;
  productTitle: string;
}
interface ItemDataProps {
  productTitle: string;
  quantity: number;
  itemPrice: number;
}

export const useCartMenuItems = (
  cartItems: FormattedCartItem[],
  products: Product[]
) =>
  useMemo(() => {
    const formattedMenuItems = formatMenuItems(cartItems, products);
    return formattedMenuItems.map(({ title, price, quantity, imageUrl }) => {
      return (
        <div
          id="cart-menu-item"
          className="cart-item-menu"
          style={{ display: "flex" }}
        >
          <ItemImage productImageUrl={imageUrl} productTitle={title} />
          <span className="item-right ">
            <ItemData
              productTitle={title}
              quantity={quantity}
              itemPrice={price}
            />
          </span>
        </div>
      );
    });
  }, [cartItems, products]);

const formatMenuItems = (
  cartItems: FormattedCartItem[],
  products: Product[]
): MenuItem[] =>
  cartItems.flatMap((cartItem: FormattedCartItem) => {
    const selectedProduct = products.find(
      ({ id }) => id === cartItem.productId
    );
    if (selectedProduct) {
      return {
        id: selectedProduct.id,
        title: selectedProduct.title,
        imageUrl: selectedProduct.thumbnailUrl,
        quantity: cartItem.quantity,
        key: cartItem.key,
        price: cartItem.discount?.discountedPrice ?? cartItem.lineTotal,
        productCategories: selectedProduct.categories,
      };
    } else {
      return [];
    }
  });

// const DeleteItem = ({
//   productId,
//   cartItemKey,
//   productCategories,
// }: DeleteItemProps) => {
//   const showDelete = productCategories.some(
//     (category) => category === "physical"
//   );
//   if (showDelete) {
//     return (
//       <i
//         id={`menu-cart-remove-${productId}`}
//         className="fa fa-trash menu-cart-remove"
//         aria-hidden="true"
//         style={{ color: "gray", paddingTop: "1rem", fontSize: "15" , 'position' : 'absolute', 'top' : '4rem', 'right' : '2rem'}}
//         data-product-key={cartItemKey}
//       />
//     );
//   }
//   return null;
// };

const ItemImage = ({ productImageUrl, productTitle }: ItemImageProps) => {
  return (
    <span className="item-left">
      <div className="cart-item-image">
        <img
          src={productImageUrl}
          alt={productTitle}
          width="125"
          height="100"
        />
      </div>
    </span>
  );
};

const ItemData = ({ productTitle, quantity, itemPrice }: ItemDataProps) => {
  return (
    <div className="shop-title-cart">
      <span className="cart-item-title">{productTitle}</span>

      <div className="cart-item-price" style={{ marginTop: "2rem" }}>
        <span className="cart-item-price">
          {" "}
          Qty:
          {quantity}
        </span>

        <p style={{ fontWeight: "bold", float: "right" }}>
          ${itemPrice.toFixed(2)} AUD
        </p>
      </div>
    </div>
  );
};
