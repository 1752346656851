import { Dropdown } from "./dropdown/Dropdown";
import { FORM_FIELDS } from "./formFields.consts";
import {
  CheckboxFormField,
  DropdownFormField,
  FormField,
  TextFormField,
} from "./formFields.types";
import { FormTextInput } from "./text-input/FormTextInput";
import "./formFields.css";
import { FormCheckboxInput } from "./checkbox/Checkbox";
import { FormTextAreaInput } from "./text-area/FormTextArea";

interface FormFieldProps {
  fieldName: string;
  field: FormField;
}
export const FormFields = ({ fieldName, field }: FormFieldProps) => {
  switch (field.inputType) {
    case FORM_FIELDS.DROPDOWN: {
      const { options, label, required, placeholder } =
        field as DropdownFormField;
      return (
        <Dropdown
          fieldName={fieldName}
          options={options}
          label={label}
          required={required}
          placeholder={placeholder}
        />
      );
    }
    case FORM_FIELDS.TEXT_AREA: {
      return (
        <FormTextAreaInput
          fieldName={fieldName}
          label={field.label}
          required={field.required}
        />
      );
    }
    case FORM_FIELDS.CHECKBOX: {
      const { component } = field as CheckboxFormField;
      return (
        <FormCheckboxInput
          fieldName={fieldName}
          label={field.label}
          required={field.required}
          component={component}
        />
      );
    }
    case FORM_FIELDS.TEXT:
    default: {
      const { numericOnly, maxLength, password, placeholder } =
        field as TextFormField;
      return (
        <FormTextInput
          fieldName={fieldName}
          label={field.label}
          required={field.required}
          numericOnly={numericOnly}
          maxLength={maxLength}
          password={password}
          placeholder={placeholder}
        />
      );
    }
  }
};
