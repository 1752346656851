import {
  CartTableData,
  DiscountDisplayData,
} from "../cartDetailsDisplay.types";
import { useRemoveFromCart } from "../hooks/useRemoveFromCart";

interface CartTableItemsProps {
  tableData: CartTableData[];
  readOnly: boolean;
}

interface ItemSubtotalProps {
  totalItemPrice: string;
  discount: DiscountDisplayData | null;
}

export const CartTableItems = ({
  tableData,
  readOnly,
}: CartTableItemsProps) => {
  const removeItemFromCart = useRemoveFromCart();
  return (
    <>
      {tableData.map(({ lineItemTitle, lineItemPrice, discount }, index) => (
        <tr>
          <td>{lineItemTitle}</td>
          <ItemSubtotal totalItemPrice={lineItemPrice} discount={discount} />
          {!readOnly && (
            <td onClick={() => removeItemFromCart(index)}>
              <a className="remove-item">×</a>
            </td>
          )}
        </tr>
      ))}
    </>
  );
};

const ItemSubtotal = ({ totalItemPrice, discount }: ItemSubtotalProps) => {
  if (!discount) {
    return <td>{totalItemPrice}</td>;
  } else {
    return (
      <td>
        <span className="original-price">
          ${Number(discount.originalPrice).toFixed(2)}
        </span>{" "}
        {discount?.price}
      </td>
    );
  }
};
