import { useCallback } from "react";
import { useCheckoutContext } from "../../context/checkout.context";

export const useSaveAdditionalFields = () => {
  const { dispatch } = useCheckoutContext();
  return useCallback(
    (formData: Record<string, string>) => {
      dispatch({
        type: "SET_ADDITIONAL_INFO",
        payload: {
          notes: formData.notes,
          termsAndConditions: Boolean(formData.termsAndConditions),
        },
      });
    },
    [dispatch]
  );
};
