import { useCallback } from "react";
import { useCheckoutContext } from "../../../context/checkout.context";
import { useAppContext } from "../../../../../context/app.context";
import { submitCheckout } from "../../../../../services/checkout/checkout";
import { emptyCart } from "../../../../../services/cart/cart";

const STOP_LOADING = 1000;
export const useHandleSubmit = () => {
  const {
    dispatch,
    state: { cartItems },
  } = useAppContext();
  const {
    dispatch: checkoutDispatch,
    state: { formData, notes, selectedPaymentMethod },
  } = useCheckoutContext();

  return useCallback(async () => {
    dispatch({
      type: "SET_LOADING",
      payload: { isLoading: true, loadingMessage: "Loading please wait" },
    });
    const paymentPayload = {
      cartItems,
      notes,
      formData,
      selectedPaymentMethod,
    };
    const checkoutData = await submitCheckout(paymentPayload);
    const { errorMessage, redirect } = checkoutData;
    if (errorMessage) {
      window.scrollTo(0, 0);
    }
    checkoutDispatch({
      type: "SET_ERRORS",
      payload: { submissionErrors: errorMessage ?? null },
    });
    emptyCart();
    if (redirect) {
      window.location.href = redirect;
    }
    setTimeout(() => {
      dispatch({
        type: "SET_LOADING",
        payload: { isLoading: false, loadingMessage: "Loading please wait" },
      });
    }, STOP_LOADING);
  }, [
    formData,
    notes,
    cartItems,
    dispatch,
    selectedPaymentMethod,
    checkoutDispatch,
  ]);
};
