import { useEffect } from "react";
import { useQuery } from "react-query";
import { useAppContext } from "../../../context/app.context";
import { FormattedOrderResponse } from "../../../models/cart";
import { Product } from "../../../models/products/products.types";
import { getProducts } from "../../../services/products/products";
import { useApplyDiscounts } from "../hooks/useApplyDiscounts";
import { ORDER_PAY_STATUSES } from "../checkout.consts";
import { useCheckoutContext } from "../context/checkout.context";
import { getOrderData } from "../../../services/cart/cart";

export const useOrderCartItems = (orderId: number, orderKey: string) => {
  // Fetch data
  const products = useQuery<Product[]>(["products"], () => getProducts());
  const { data, isLoading } = useQuery<FormattedOrderResponse>(
    ["order-data"],
    () => getOrderData(orderId, orderKey)
  );
  const { dispatch } = useAppContext();
  const { dispatch: checkoutDispatch } = useCheckoutContext();

  const discountsApplied = useApplyDiscounts(
    data?.formattedCartItems ?? [],
    products?.data ?? [],
    data?.discount
  );

  useEffect(() => {
    dispatch({ type: "IS_LOADING_CART", payload: isLoading });
  }, [isLoading]);

  useEffect(() => {
    if (data?.status && !ORDER_PAY_STATUSES.includes(data.status)) {
      checkoutDispatch({
        type: "SET_ERRORS",
        payload: {
          submissionErrors: `Cannot pay for order when status is ${data?.status}`,
        },
      });
    }
  }, [data?.status]);

  // return and set in state
  useEffect(() => {
    if (
      products.data &&
      data?.formattedCartItems &&
      data?.status &&
      ORDER_PAY_STATUSES.includes(data.status)
    ) {
      dispatch({ type: "SET_PRODUCTS", payload: products.data });
      dispatch({
        type: "UPDATE_CART",
        payload: { cartItems: discountsApplied },
      });
      dispatch({
        type: "UPDATE_COUPONS",
        payload: { coupons: data.coupons ?? [] },
      });
    }
  }, [products.data, discountsApplied, dispatch, data?.coupons]);

  if ((products.data && data?.formattedCartItems, data?.coupons)) {
    return {
      isLoading: false,
      displayContent: data?.status && ORDER_PAY_STATUSES.includes(data.status),
    };
  } else {
    return { isLoading: true };
  }
};
