import { Field } from "formik";
import { REQUIRED, TEXT_INPUT_TYPES } from "../formFields.consts";

interface TextInputProps {
  fieldName: string;
  label?: string;
  password?: boolean;
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  numericOnly?: boolean;
}
export const FormTextInput = ({
  label,
  fieldName,
  required,
  maxLength,
  placeholder,
  numericOnly,
  password,
}: TextInputProps) => {
  const inputType = password
    ? TEXT_INPUT_TYPES.PASSWORD
    : TEXT_INPUT_TYPES.TEXT;

  return (
    <div className="form-field-container">
      <label className="form-field-label">
        {label ?? fieldName}
        {required ? (
          <span className="form-field-required">{REQUIRED}</span>
        ) : (
          ""
        )}
      </label>
      <span className="woocommerce-input-wrapper">
        <Field
          as={"input"}
          type={inputType}
          name={fieldName}
          placeholder={placeholder}
          maxlength={maxLength}
          pattern={numericOnly ? "[0-9]*" : undefined}
          className="input-text form-control"
        />
      </span>
    </div>
  );
};
