import { useCallback } from "react";
import { useAppContext } from "../../../context/app.context";
import { FormattedCartItem } from "../../../models/cart";
import { addItemToCart, updateItemInCart } from "../../../services/cart/cart";

export const useAddProduct = () => {
  const {
    dispatch,
    state: { cartItems },
  } = useAppContext();

  const addProduct = useCallback(
    async (productId: number) => {
      // see if it's in cart items - then update quantity
      if (isInCart(cartItems, productId)) {
        const item = getFromCart(cartItems, productId);
        if (item) {
          const { key, quantity } = item;
          const updateParams = { key, productId, quantity: quantity + 1 };
          const updatedCart = await updateItemInCart(updateParams);

          dispatch({
            type: "UPDATE_CART",
            payload: { cartItems: updatedCart.formattedCartItems },
          });
          return updatedCart;
        }
      } else {
        // if its not add
        const addItemParams = { productId, quantity: 1 };
        const updatedCart = await addItemToCart(addItemParams);

        dispatch({
          type: "UPDATE_CART",
          payload: { cartItems: updatedCart.formattedCartItems },
        });
        return updatedCart.formattedCartItems;
      }
    },
    [cartItems, dispatch]
  );

  return addProduct;
};

const getFromCart = (
  cartItems: FormattedCartItem[],
  selectedProductId: number
) => cartItems.find(({ productId }) => productId === selectedProductId);
const isInCart = (cartItems: FormattedCartItem[], selectedProductId: number) =>
  cartItems.some(({ productId }) => productId === selectedProductId);
