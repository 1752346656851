import {
  ApplyCouponResponse,
  SubmitCheckoutResponse,
} from "../../models/checkout/checkout.types";
import { CheckoutPageContent } from "../../views/checkout/checkout.types";

import { fetchWPData, postWPData } from "../network";

export const getCheckoutContent = async (): Promise<CheckoutPageContent> => {
  const CHECKOUT_CONTENT_URI = `clean-edge/v2/checkout-content/`;
  return await fetchWPData(CHECKOUT_CONTENT_URI).then((data) => {
    return data as CheckoutPageContent;
  });
};

const PAYMENT_DATA_URI = "clean-edge/v2/payment-data/";
export const fetchPaymentData = async (): Promise<any> => {
  return await fetchWPData(PAYMENT_DATA_URI).then((data) => {
    return data;
  });
};

const SUBMIT_CHECKOUT_URL = "clean-edge/v2/submit-checkout/";
export const submitCheckout = async (
  checkoutData: any
): Promise<SubmitCheckoutResponse> => {
  return await postWPData(SUBMIT_CHECKOUT_URL, { ...checkoutData })
    .then((responseData) => {
      return responseData as SubmitCheckoutResponse;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

const COUPON_URL = "clean-edge/v2/apply-coupon";
export const applyCoupon = async (couponData: {
  couponCode: string;
}): Promise<ApplyCouponResponse> => {
  return await postWPData(COUPON_URL, { ...couponData })
    .then((responseData) => {
      return responseData as ApplyCouponResponse;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

const PAY_URL = "clean-edge/v2/process-payment";
export const processPayment = async (paymentPayload: {
  encryptedCardDetails: string;
  orderKey: string;
  orderId: number;
}): Promise<any> => {
  return await postWPData(PAY_URL, paymentPayload)
    .then((responseData) => {
      return responseData;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};
