import { useMemo } from "react";
import { CartTableData } from "../cartDetailsDisplay.types";

interface RecurringTotalsProps {
  tableItems: CartTableData[];
}
export const RecurringTotals = ({ tableItems }: RecurringTotalsProps) => {
  const recurringItems = useMemo(
    () => tableItems.filter((item) => item.isRecurring),
    [tableItems]
  );
  if (!recurringItems.length) {
    return null;
  }
  return (
    <tfoot>
      <tr className="recurring-totals">
        <td>
          <strong>Recurring Totals</strong>
        </td>
        <td />
        <td />
      </tr>
      {recurringItems.map(({ discount, lineItemPrice, recurringDate }) => (
        <tr className="order-total">
          <td>
            <strong>Total</strong>
          </td>
          <td className="recurring-details">
            <strong>
              <span className="woocommerce-Price-amount amount">
                {discount?.recurring?.price
                  ? discount.recurring.price
                  : lineItemPrice}
              </span>
            </strong>
            <span className="first-renewal">
              First renewal: {recurringDate}
            </span>
          </td>
          <td></td>
        </tr>
      ))}
    </tfoot>
  );
};
