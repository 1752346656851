import { snakeToCamel } from "../utils/snakeToCamel";

export const fetchWPData = async (uri: string) => {
  const root = getUrlRoot();
  try {
    const response = await fetch(`${root}/wp-json/${uri}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const errorMessage = responseData.message ?? "Post Request Failed";
      throw new Error(errorMessage);
    }
    const formattedResponseData = snakeToCamel(responseData);
    return formattedResponseData;
  } catch (error) {
    return { errorMessage: error };
  }
};

export const postWPData = async (
  uri: string,
  body: Record<string, unknown>
) => {
  const root = getUrlRoot();
  try {
    const response = await fetch(`${root}/wp-json/${uri}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const errorMessage = responseData.message ?? "Post Request Failed";
      throw new Error(errorMessage);
    }

    const formattedResponseData = snakeToCamel(responseData);
    return formattedResponseData;
  } catch (error) {
    // @ts-ignore
    return { errorMessage: error.message };
  }
};

export const getUrlRoot = () => {
  const hostname = window.location.hostname;
  if (hostname.indexOf("localhost") !== -1) {
    return "http://localhost/tunnel-express";
  }
  if (hostname.indexOf("dev") !== -1) {
    return "https://dev.tunnelexpress.com.au";
  }
  if (hostname.indexOf("staging") !== -1) {
    return "https://staging.tunnelexpress.com.au";
  }
  return "https://tunnelexpress.com.au";
};
