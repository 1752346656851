import {
  CartData,
  CartResponseItem,
  CouponData,
  FormattedCartItem,
  FormattedCartResponse,
} from "../../models/cart";
import { Product } from "../../models/products/products.types";
import { snakeToCamel } from "../../utils/snakeToCamel";

export const formatCartItemsFromResponse = (
  itemsData: CartData
): FormattedCartResponse => {
  const formattedCartItems: FormattedCartItem[] = [];

  const { discount, coupons } = itemsData;

  const formattedCoupons = coupons.map((coupon) =>
    snakeToCamel(coupon)
  ) as unknown as CouponData[];

  const formattedDiscount = snakeToCamel(discount) as Record<string, boolean>;
  // Loop through each item in the itemsData object
  const responseItems = itemsData.items as CartResponseItem;
  for (const propertyKey in responseItems) {
    if (responseItems.hasOwnProperty(propertyKey)) {
      const item = responseItems[propertyKey as keyof CartResponseItem];
      const { lineTotal, quantity, productId, key, lineSubtotal } = item;
      const cartItem: FormattedCartItem = {
        lineSubtotal,
        lineTotal,
        quantity,
        productId,
        key,
      };
      formattedCartItems.push(cartItem);
    }
  }

  return {
    formattedCartItems,
    discount: formattedDiscount,
    coupons: formattedCoupons,
  };
};

export const productToCartItem = (product: Product) => {
  const productId = product.id;
  const lineTotal = Number(product.price);
  const quantity = 1;
  const key = `added-recently-${productId}`;
  return { productId, lineTotal, quantity, key };
};

export const productIsInCart = (productId: number, cart: FormattedCartItem[]) =>
  cart.findIndex((item) => item.productId === productId) !== -1;
