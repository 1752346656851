import { SubscriptionResponseData } from "../../models/cart";
import { formatCartItemsFromResponse } from "./cart.helpers";

const EMPTY_CART = {
  formattedCartItems: [],
  discount: {},
  coupons: [],
};
export const formatSubscriptionData = (
  subscriptionResponse: SubscriptionResponseData
) => {
  const { cart, formData, errorMessage } = subscriptionResponse;
  if (errorMessage) {
    return { errorMessage, ...EMPTY_CART, formData: {} };
  }
  if (cart) {
    const formattedCart = formatCartItemsFromResponse(cart);
    return { ...formattedCart, formData };
  }
  return { errorMessage: "No items found in cart" };
};
