import { useAppContext } from "../../context/app.context";
import "./loading.css";
export const LoadingOverlay = () => {
  const {
    state: { displayLoading, loadingMessage },
  } = useAppContext();

  if (!displayLoading) {
    return null;
  }
  return (
    <div className="loading-overlay-container">
      <div className="loading-overlay" />
      <div className="loading-content">
        {loadingMessage && (
          <h1 className="loading-overlay-message">{loadingMessage}</h1>
        )}
        <span className="loader-spinner"></span>
      </div>
    </div>
  );
};
