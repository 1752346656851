import { Notice } from "../../../../components/notice/Notice";
import { NOTICE_TYPES } from "../../../../components/notice/notice.consts";
import { useCheckoutContext } from "../../context/checkout.context";

export const ErrorMessages = () => {
  const {
    state: { submissionErrors },
  } = useCheckoutContext();
  const hasErrors = submissionErrors;

  if (hasErrors) {
    return (
      <Notice type={NOTICE_TYPES.ERROR}>
        <div>{submissionErrors}</div>
      </Notice>
    );
  }
  return null;
};
