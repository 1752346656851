import { FormField } from "../../components/form-fields/formFields.types";

export const phoneNumberValidation = (phoneNumber: string): boolean => {
  // Regular expression to match 10 digits starting with 0
  const regex = /^0\d{9}$/;
  return regex.test(phoneNumber);
};

export const postCodeValidation = (postCode: string): boolean => {
  // Check if the postCode is numeric and has 4 digits
  return /^\d{4}$/.test(postCode);
};

export const validateRego = (rego: string): boolean => {
  return rego.length >= 1 && rego !== " ";
};

export const validateAllRequired = (
  formFields: Record<string, FormField>,
  formValues: Record<string, string>
) => {
  const errors: Record<string, string> = {};
  Object.keys(formFields).forEach((key) => {
    const fieldItem = formFields[key];
    if (fieldItem.required && !formValues[key]) {
      errors[key] = `${fieldItem.label} Required`;
    }
  });
  return errors;
};
