import { Product } from "../../../../../models/products/products.types";
import {
  getIsRecurring,
  getMembershipDuration,
  getRecurringDate,
} from "./getMembershipDuration";

export const getRecurringItemData = (selectedProduct: Product) => {
  const isRecurring = getIsRecurring(selectedProduct);
  if (isRecurring) {
    const membershipDuration = getMembershipDuration(selectedProduct);
    const recurringDate = getRecurringDate(membershipDuration ?? 0);
    return { isRecurring, recurringDate };
  }
  return { isRecurring, recurringDate: null };
};
