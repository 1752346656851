import { useMemo } from "react";
import { useLocation } from "react-router-dom";
export const useIsResubscribing = () => {
  const location = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const subscriptionId = searchParams.get("subscriptionId");
    if (subscriptionId) {
      return { subscriptionId: Number(subscriptionId) };
    }
    return null;
  }, [location]);
};
