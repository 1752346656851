import { useState } from "react";
import { Notice } from "../../../../components/notice/Notice";
import { Login } from "../../../login/Login";
import { Modal } from "../../../../components/modal/Modal";

const RETURNING_CUSTOMER_MESSAGE =
  "If you have shopped with us before, please enter your details below. If you are a new customer, please proceed to the Billing section.";
export const ReturningCustomer = () => {
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const handleShowLoginClick = () => {
    setShowLogin(!showLogin);
  };
  return (
    <>
      <Notice>
        <div>
          Returning customer?{" "}
          <a onClick={handleShowLoginClick}>Click here to login</a>
        </div>
      </Notice>
      <Modal isOpen={showLogin} onClose={handleShowLoginClick}>
        <Login message={RETURNING_CUSTOMER_MESSAGE} />
      </Modal>
    </>
  );
};
