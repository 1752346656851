import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useIsOrderPay = () => {
  const location = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get("orderId");
    const orderKey = searchParams.get("key");
    if (orderId && orderKey) {
      return { orderId: Number(orderId), orderKey };
    }
    return null;
  }, [location]);
};
