import { Field, ErrorMessage } from "formik";
import { ReactNode } from "react";
import { REQUIRED } from "../formFields.consts";

interface CheckboxInputProps {
  fieldName: string;
  label?: string;
  required?: boolean;
  component?: ReactNode;
}

export const FormCheckboxInput = ({
  label,
  fieldName,
  required,
  component,
}: CheckboxInputProps) => {
  return (
    <div className="form-field-container">
      <label className="form-field-label">
        <Field
          type="checkbox" // Render as checkbox
          name={fieldName}
          className="checkbox-input" // Adjust class for checkbox styling
          required={required}
        />
        {component ?? label ?? fieldName}
        {required ? (
          <span className="form-field-required">{REQUIRED}</span>
        ) : (
          ""
        )}
      </label>
      <ErrorMessage name={fieldName} component="div" />
    </div>
  );
};
