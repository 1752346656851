import { LoginData, LoginUserResponse } from "../../models/user";
import { postWPData } from "../network";

const LOGIN_URL = "clean-edge/v2/login/";
export const login = async (
  loginData: LoginData
): Promise<LoginUserResponse> => {
  return await postWPData(LOGIN_URL, { ...loginData })
    .then((responseData) => {
      return responseData;
    })
    .catch((error) => {
      return error;
    });
};
