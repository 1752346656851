import { useCallback } from "react";
import { useCheckoutContext } from "../../context/checkout.context";

export const useSaveForm = () => {
  const { dispatch } = useCheckoutContext();

  const handleSaveForm = useCallback(
    (formData: Record<string, string>) => {
      dispatch({ type: "SET_FORM_DATA", payload: formData });
    },
    [dispatch]
  );

  const saveFormValidity = useCallback(
    (isValid: boolean) => {
      dispatch({ type: "SET_FORM_VALID", payload: isValid });
    },
    [dispatch]
  );

  const saveErroredFields = useCallback(
    (erroredFields: Record<string, string>) => {
      dispatch({ type: "SAVE_ERRORED_FIELDS", payload: { erroredFields } });
    },
    [dispatch]
  );
  return { handleSaveForm, saveFormValidity, saveErroredFields };
};
