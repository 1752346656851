import { ErrorMessages } from "../error-messages/ErrorMessages";

export const NoCheckoutItems = () => {
  return (
    <div className="no-items">
      <p>
        <ErrorMessages />
        <strong>
          Your cart is currently empty. Checkout is not available while your
          cart is empty
        </strong>
      </p>
    </div>
  );
};
