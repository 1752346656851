import React from "react";
import { useIsOrderPay } from "./hooks/useIsOrderPay";
import { OrderPay } from "./order-pay/OrderPay";
import { CheckoutPage } from "./checkout-page/CheckoutPage";
import "./checkout.css";

export const Checkout: React.FC = () => {
  const orderPayData = useIsOrderPay();
  return (
    <>
      {orderPayData !== null ? (
        <OrderPay
          orderId={orderPayData.orderId}
          orderKey={orderPayData.orderKey}
        />
      ) : (
        <CheckoutPage />
      )}
    </>
  );
};
