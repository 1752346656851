import { FeaturedProduct, Product } from "../../models/products/products.types";
import { fetchWPData } from "../network";

const PRODUCTS_URI = "clean-edge/v2/products/";
export const getProducts = async (): Promise<Product[]> => {
  return await fetchWPData(PRODUCTS_URI)
    .then((data) => {
      return data as unknown as Product[];
    })
    .catch((error) => {
      console.error("Error:", error);
      return [];
    });
};

const FEATURED_PRODUCTS = "clean-edge/v2/featured-products";
export const fetchFeaturedProducts = async (): Promise<FeaturedProduct[]> => {
  return await fetchWPData(FEATURED_PRODUCTS)
    .then((data) => {
      // @ts-ignore
      const { posts } = data;
      return posts as FeaturedProduct[];
    })
    .catch((error) => {
      console.error("Error:", error);
      return [];
    });
};
