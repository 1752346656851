interface ActionButtonProps {
  title: string;
  onClick: () => void;
  isDisabled?: boolean;
}
export const ActionButton = ({
  title,
  onClick,
  isDisabled = false,
}: ActionButtonProps) => {
  return (
    <button className="action-button" disabled={isDisabled} onClick={onClick}>
      {title}
    </button>
  );
};
