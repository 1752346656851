import { useCallback } from "react";
import { useAppContext } from "../../../../../context/app.context";
import { removeItemFromCart } from "../../../../../services/cart/cart";
import { redirectTo } from "../../../../../utils/redirectTo";

export const useRemoveFromCart = () => {
  const {
    dispatch,
    state: { cartItems },
  } = useAppContext();
  const removeFromCart = useCallback(
    async (index: number) => {
      const [removedItem] = cartItems.splice(index, 1);
      if (removedItem.key.indexOf("added-recently-") === -1) {
        const removeItemParameters = {
          productId: removedItem.productId,
          key: removedItem.key,
        };
        const updatedCart = await removeItemFromCart(removeItemParameters);
        dispatch({ type: "UPDATE_CART", payload: { cartItems: updatedCart } });
        return;
      }
      dispatch({ type: "UPDATE_CART", payload: { cartItems } });
      redirectTo("/te-checkout");
    },
    [cartItems, dispatch]
  );

  return removeFromCart;
};
