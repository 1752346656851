export const reorderObjectKeys = (
  obj: Record<string, unknown>,
  keyOrder: string[]
) => {
  const reorderedObj: Record<string, unknown> = {};
  const remainingKeys = { ...obj }; // Clone the original object

  keyOrder.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      reorderedObj[key] = obj[key];
      delete remainingKeys[key]; // Remove added key from remaining keys
    }
  });

  // Add any remaining keys to the reordered object
  for (const key in remainingKeys) {
    if (remainingKeys.hasOwnProperty(key)) {
      reorderedObj[key] = remainingKeys[key];
    }
  }

  return reorderedObj;
};
