import { useEffect, useState } from "react";
import { Notice } from "../../../../components/notice/Notice";
import { COUPON_FIELDS } from "./coupon.consts";
import { validateAllRequired } from "../../../../utils/form/validation";
import { isObjectEmpty } from "../../../../utils/isObjectEmpty";
import { Form, Formik } from "formik";
import { FormFields } from "../../../../components/form-fields/FormFields";
import { ActionButton } from "../../../../components/cta-button/actionButton";
import { useHandleApplyCoupon } from "./useHandleApplyCoupon";
import { FormError } from "../../../../components/form-fields/form-error/FormError";
import { FormMessage } from "../../../../components/form-fields/form-message/formMessage";
import "./coupon.css";

const CLOSE_COUPON_TIMEOUT = 7000;
export const Coupon = () => {
  const [showAddCoupon, setShowAddCoupon] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<null | string>(null);
  const handleAddCouponClick = () => {
    setShowAddCoupon(!showAddCoupon);
  };
  const couponForm = { ...COUPON_FIELDS };
  const { applyCouponCallback, message, isSuccess } = useHandleApplyCoupon(
    couponCode ?? ""
  );

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setShowAddCoupon(false);
      }, CLOSE_COUPON_TIMEOUT);
    }
  }, [isSuccess, setShowAddCoupon]);
  return (
    <>
      <Notice>
        <div>
          Have a coupon?{" "}
          <a onClick={handleAddCouponClick}>Click here to enter your code</a>
        </div>
      </Notice>
      {showAddCoupon && (
        <div key={String(isSuccess)} className="coupon-form">
          {message && !isSuccess && (
            <FormError
              key={`${message} error ${showAddCoupon}`}
              errorMessage={`Coupon not applied: ${message}`}
            />
          )}
          {message && isSuccess && (
            <FormMessage
              key={`${message} ${showAddCoupon}`}
              message={message}
            />
          )}
          <Formik
            initialValues={{ coupon: "" }}
            validateOnBlur
            validate={(values) => {
              const requiredErrors = validateAllRequired(couponForm, values);
              if (isObjectEmpty(requiredErrors)) {
              }
              setCouponCode(values.coupon);
              return requiredErrors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ errors }) => (
              <Form>
                {Object.keys(couponForm).map((value: string, index) => {
                  // @ts-ignore
                  const fieldItem = couponForm[value];
                  return (
                    <>
                      <FormFields
                        key={`${value} ${index}`}
                        fieldName={value}
                        field={fieldItem}
                      />
                    </>
                  );
                })}
              </Form>
            )}
          </Formik>
          <ActionButton
            title={"Apply Coupon"}
            onClick={() => applyCouponCallback()}
          />
        </div>
      )}
    </>
  );
};
