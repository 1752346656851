import { Form, Formik } from "formik";
import { useAdditionalInformationFields } from "./useAdditionalInformationFields";
import { FormFields } from "../../../../components/form-fields/FormFields";
import "./additionalInformation.css";
import { validateAllRequired } from "../../../../utils/form/validation";
import { isObjectEmpty } from "../../../../utils/isObjectEmpty";
import { useSaveAdditionalFields } from "./useSaveAdditionalFields";

export const AdditionalInformation = () => {
  const additionalInformationFields = useAdditionalInformationFields();
  const handleSaveForm = useSaveAdditionalFields();
  return (
    <div id="customer_details">
      <Formik
        initialValues={{ email: "" }}
        validate={(values) => {
          const requiredErrors = validateAllRequired(
            additionalInformationFields,
            values
          );
          if (isObjectEmpty(requiredErrors)) {
            handleSaveForm(values);
          }
          return requiredErrors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <h3 className="section-header">Additional Information</h3>
            {Object.keys(additionalInformationFields).map(
              (value: string, index) => {
                // @ts-ignore
                const fieldItem = additionalInformationFields[value];
                return (
                  <FormFields
                    key={`${value} ${index}`}
                    fieldName={value}
                    field={fieldItem}
                  />
                );
              }
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
