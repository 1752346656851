import { CouponData } from "../../models/cart";
import { NestedObject, snakeToCamel } from "../../utils/snakeToCamel";

export const formatAppliedCoupons = (appliedCoupons: string[]) => {
  const formattedCoupons: CouponData[] = [];
  appliedCoupons.forEach((stringifiedCoupons) => {
    try {
      const coupons = JSON.parse(stringifiedCoupons) as CouponData[];
      const existingCouponIds = formattedCoupons.map(({ id }) => id);
      const uniqueCoupons = coupons.flatMap((coupon) =>
        existingCouponIds.includes(coupon.id) ? [] : coupon
      );
      const formattedCoupon = snakeToCamel(
        uniqueCoupons as unknown as NestedObject
      );
      formattedCoupons.push(...(formattedCoupon as unknown as CouponData[]));
    } catch (error) {
      console.error("Could not parse coupon");
    }
  });
  return formattedCoupons;
};
