import { useMemo, useCallback } from "react";

export const useSavedFormData = (formDataKey: string) => {
  const savedFormData = useMemo(() => {
    const storedFormData = sessionStorage.getItem(formDataKey);
    if (storedFormData) {
      // Parse the stored data as JSON
      return JSON.parse(storedFormData);
    }
    return null;
  }, [formDataKey]);

  const saveFormData = useCallback(
    (formData: Record<string, number | string | boolean>) => {
      // Save form data to session storage
      sessionStorage.setItem(formDataKey, JSON.stringify(formData));
    },
    [formDataKey]
  );

  return { savedFormData, saveFormData };
};
