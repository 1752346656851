import { useQuery } from "react-query";
import { getCheckoutContent } from "../../../services/checkout/checkout";
import { CheckoutPageContent } from "../checkout.types";

export const useCheckoutPageContent = (): {
  data: CheckoutPageContent;
  isLoading: boolean;
} => {
  const checkoutPageData = useQuery<CheckoutPageContent>(["page-data"], () =>
    getCheckoutContent()
  );

  if (checkoutPageData.data) {
    return { data: checkoutPageData.data, isLoading: false };
  } else {
    return {
      data: { locations: [], heroImageUri: "", userId: null },
      isLoading: true,
    };
  }
};
