import { useQuery } from "react-query";
import { fetchFeaturedProducts } from "../../../../services/products/products";
import { useAppContext } from "../../../../context/app.context";
import { useMemo } from "react";
import { FeaturedProduct } from "../../../../models/products/products.types";

export const useFeaturedProductData = () => {
  const {
    state: { products },
  } = useAppContext();

  const featuredProductItems = useQuery<FeaturedProduct[]>(
    ["featured-product-data"],
    () => fetchFeaturedProducts()
  );

  const featuredProducts = useMemo(() => {
    if (featuredProductItems.data) {
      return featuredProductItems.data.flatMap((item) => {
        const product = products.find((product) => product.id === item.ID);
        if (product) {
          return product;
        }
        return [];
      });
    }
  }, [products, featuredProductItems]);
  return featuredProducts;
};
