import { FORM_FIELDS } from "../../components/form-fields/formFields.consts";

export const CHECKOUT_FIELD_ORDER = [
  "email",
  "firstName",
  "lastName",
  "streetAddress",
  "postCode",
  "mobile",
  "location",
  "washcodeReceiveMethod",
];
export const DEFAULT_CHECKOUT_FORM_FIELDS = {
  email: {
    label: "Email",
    required: true,
  },
  firstName: {
    label: "First Name",
    required: true,
  },
  lastName: {
    label: "Last Name",
    required: true,
  },
  postCode: {
    label: "Postcode",
    required: true,
    numericOnly: true,
    maxLength: 4,
  },
  mobile: {
    label: "Mobile",
    required: true,
    numericOnly: true,
    maxLength: 10,
  },
  location: {
    label: "Location",
    required: true,
    placeholder: "Select location",
    inputType: FORM_FIELDS.DROPDOWN,
  },
};

export const PHYSICAL_PRODUCT_FORM_FIELDS = {
  streetAddress: { label: "Street Address", required: true },
  postCode: { label: "Postcode", required: true },
};

export const DOB_FIELDS = {};
export const WASHCODE_FORM_FIELDS = {
  washcodeReceiveMethod: {
    label: "How would you like to receive your wash code?",
    placeholder: "Select method to receive wash code",
    required: true,
    inputType: FORM_FIELDS.DROPDOWN,
    options: [
      { value: 1, label: "Email" },
      { value: 2, label: "SMS" },
      { value: 3, label: "Both" },
    ],
  },
};

export const ADDITIONAL_INFO = {
  notes: {
    label: "Order Notes",
    required: false,
    inputType: FORM_FIELDS.TEXT_AREA,
  },
};

export const TERMS_AND_CONDITIONS = {
  required: false,
  label: "termsAndConditions",
  inputType: FORM_FIELDS.CHECKBOX,
};
export const VEHICLE_REGISTRATION = {
  vehicleRegistration: {
    label: "Vehicle Registration",
    required: true,
  },
};

export const PAYMENT_GATEWAYS = {
  NAB_DP: "nab_dp",
};

export const CHECKOUT_CONTENT_TYPES = {
  ORDER_PAY: "ORDER_PAY",
  CHECKOUT_FORM: "CHECKOUT_FORM",
};

export const DEFAULT_PAYMENT = "DEFAULT";

export const COUPON_DISCOUNT_TYPES = {
  PERCENT: "percent",
  FIXED_CART: "fixed_cart",
  RECURRING_FEE: "recurring_fee",
  RECURRING_PERCENT: "recurring_percent",
  FIXED_PRODUCT: "fixed_product",
};

export const COUPON_DISCOUNT = "COUPON";
export const MULTI_MEMBERSHIP_DISCOUNT = {
  key: "multiMembershipDiscount",
  productAttribute: "secondCarDiscountPrice",
  recurring: true,
};
export const DISCOUNTS = [MULTI_MEMBERSHIP_DISCOUNT];

export const NON_PERSISTENT_FIELDS = ["vehicleRegistration"];

export const CHECKOUT_DISPLAY_TIMEOUT = 4500;

const MONTH_OPTIONS = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const PAYMENT_FIELDS = {
  cardHolder: {
    label: "Card Holder",
    placeholder: "Enter Name on Card",
    required: true,
  },

  cardNumber: {
    label: "Card Number",
    numericOnly: true,
    placeholder: "xxxx xxxx xxxx xxxx",
    maxLength: 16,
    required: true,
  },
  expiryMonth: {
    label: "Expiry Month",
    placeholder: "Select Month",
    inputType: FORM_FIELDS.DROPDOWN,
    options: MONTH_OPTIONS,
    required: true,
  },
  expiryYear: {
    label: "Expiry Year",
    placeholder: "Select Year",
    inputType: FORM_FIELDS.DROPDOWN,
    options: [] as { value: string; label: string }[],
    required: true,
  },
  ccv: {
    label: "CCV",
    placeholder: "Enter CCV",
    maxLength: 4,
    numericOnly: true,
    required: true,
  },
};

export const SPECIAL_CARD_FIELDS = ["ccv", "expiryYear", "expiryMonth"];

export const ORDER_STATUSES = {
  PENDING: "pending",
  FAILED: "failed",
};

export const ORDER_PAY_STATUSES = [
  ORDER_STATUSES.FAILED,
  ORDER_STATUSES.PENDING,
];

export const RECURRING_COUPONS = [
  COUPON_DISCOUNT_TYPES.RECURRING_FEE,
  COUPON_DISCOUNT_TYPES.RECURRING_PERCENT,
];
