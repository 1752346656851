import { useMemo } from "react";
import { useAppContext } from "../../../../../context/app.context";
import { FormattedCartItem } from "../../../../../models/cart";
import { Product } from "../../../../../models/products/products.types";
import { CartTableData } from "../cartDetailsDisplay.types";
import { getProductDiscount } from "../functions/getProductDiscounts";
import { getRecurringItemData } from "../functions/getRecurringItemData";
import { getPriceString } from "../functions/getPriceString";
import { getMembershipDuration } from "../functions/getMembershipDuration";

export const useCartTableData = (): CartTableData[] => {
  const {
    state: { products, coupons, cartItems },
  } = useAppContext();
  return useMemo(
    () =>
      cartItems.flatMap((item) => {
        const selectedProduct = products.find(
          (product) => product.id === item.productId
        );

        if (selectedProduct) {
          const lineItemTitle = getLineItemTitle(selectedProduct, item);
          const lineItemPrice = getLineItemPrice(selectedProduct, item);
          const discount = getProductDiscount(selectedProduct, item, coupons);
          const { isRecurring, recurringDate } =
            getRecurringItemData(selectedProduct);
          return {
            lineItemTitle,
            lineItemPrice,
            lineTotal: item.lineTotal,
            quantity: item.quantity,
            discount,
            isRecurring: Boolean(isRecurring),
            recurringDate,
          };
        }
        return [];
      }),
    [products, cartItems, coupons]
  );
};

const getLineItemTitle = (
  selectedProduct: Product,
  item: FormattedCartItem
) => {
  if (
    selectedProduct.title.indexOf("Membership") !== -1 &&
    selectedProduct.categories.length
  ) {
    return `${selectedProduct.title} (${selectedProduct.categories[0]}) x ${item.quantity}`;
  }
  return `${selectedProduct.title} x ${item.quantity}`;
};

const getLineItemPrice = (
  selectedProduct: Product,
  item: FormattedCartItem
) => {
  const duration = getMembershipDuration(selectedProduct);
  const priceString = getPriceString(item.lineTotal, duration);
  return priceString;
};
