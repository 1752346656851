import "./ctaButton.css";
interface CTAButtonProps {
  title: string;
  onClick: () => void;
  isDisabled?: boolean;
}
export const CtaButton = ({
  title,
  onClick,
  isDisabled = false,
}: CTAButtonProps) => {
  return (
    <button className="cta-button" disabled={isDisabled} onClick={onClick}>
      {title}
    </button>
  );
};
