import React from "react";
import "./radioInput.css";
interface Option {
  id: string;
  label: string;
}

interface RadioButtonGroupProps<T extends Option> {
  options: T[];
  selectedOptionId: string | null;
  onOptionChange: (optionId: string) => void;
}

function RadioButtonGroup<T extends Option>({
  options,
  selectedOptionId,
  onOptionChange,
}: RadioButtonGroupProps<T>) {
  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const optionId = e.target.value;
    onOptionChange(optionId);
  };

  return (
    <div>
      {options.map((option) => (
        <div key={option.id}>
          <label htmlFor={`option_${option.id}`}>
            <input
              type="radio"
              className="radio-input"
              id={`option_${option.id}`}
              name="options"
              value={option.id}
              checked={selectedOptionId === option.id}
              onChange={handleOptionChange}
            />
            <span className="radio-input-label">{option.label}</span>
          </label>
        </div>
      ))}
    </div>
  );
}

export default RadioButtonGroup;
