import {
  CouponData,
  DiscountConfig,
  FormattedCartItem,
} from "../../../../../models/cart";
import { Product } from "../../../../../models/products/products.types";
import { includedInArray } from "../../../../../utils/includedInArray";
import {
  COUPON_DISCOUNT,
  COUPON_DISCOUNT_TYPES,
  RECURRING_COUPONS,
} from "../../../checkout.consts";
import { DiscountDisplayData } from "../cartDetailsDisplay.types";
import { getMembershipDuration } from "./getMembershipDuration";
import { getPriceString } from "./getPriceString";

export const getProductDiscount = (
  selectedProduct: Product,
  item: FormattedCartItem,
  coupons: CouponData[]
): DiscountDisplayData | null => {
  const { quantity, lineTotal } = item;
  if (item.discount) {
    const { discountedPrice, discountType } = item.discount ?? {};
    const recurringDiscount = getRecurringDiscounts(
      selectedProduct.price,
      item.discount,
      []
    );
    const duration = getMembershipDuration(selectedProduct);
    const priceString = getPriceString(discountedPrice, duration);

    const productData = {
      price: priceString,
      discountPriceValue: discountedPrice,
      originalPrice: Number(selectedProduct.price) * quantity,
      type: discountType,
    };
    if (recurringDiscount) {
      const recurringPriceString = recurringDiscount.price
        ? getPriceString(recurringDiscount.price, duration)
        : "";

      return {
        ...productData,
        recurring: {
          price: recurringPriceString,
        },
      };
    }
    return productData;
  } else if (coupons.length) {
    const price = Number(selectedProduct.price);
    const appliedCoupons = getAppliedCouponsForProduct(
      selectedProduct,
      coupons
    );
    if (appliedCoupons.length === 0) {
      return null;
    }
    const duration = getMembershipDuration(selectedProduct);

    const couponPrice = getCouponPrice(price, appliedCoupons, quantity);
    const recurringDiscount = getRecurringDiscounts(
      selectedProduct.price,
      null,
      coupons
    );

    const priceString = getPriceString(couponPrice, duration);

    const productDiscount = {
      price: priceString,
      discountPriceValue: couponPrice,
      originalPrice: Number(selectedProduct.price) * quantity,
      type: COUPON_DISCOUNT,
    };
    if (recurringDiscount) {
      const recurringPriceString = recurringDiscount?.price
        ? getPriceString(recurringDiscount.price, duration)
        : "";

      const recurring = {
        price: recurringPriceString,
        payments: recurringDiscount.payments,
      };
      return { ...productDiscount, recurring };
    }
    return productDiscount;
  }
  return null;
};

const getAppliedCouponsForProduct = (
  selectedProduct: Product,
  coupons: CouponData[]
) =>
  coupons.flatMap((coupon) => {
    const {
      excludedCategories,
      excludedProducts,
      includedCategories,
      includedProducts,
      discountType,
    } = coupon;
    const { id, categories } = selectedProduct;
    if (discountType === COUPON_DISCOUNT_TYPES.FIXED_CART) {
      return [];
    }
    if (
      excludedProducts.includes(id) ||
      (includedProducts.length && !includedProducts.includes(id))
    ) {
      return [];
    }
    if (
      includedInArray(excludedCategories, categories) ||
      (includedCategories.length &&
        !includedInArray(includedCategories, categories))
    ) {
      return [];
    }
    return coupon;
  });

const getCouponPrice = (
  price: number,
  coupons: CouponData[],
  quantity: number = 1
) => {
  const totalDiscount = coupons.reduce(
    (accumulatedDiscount, { amount, discountType }) => {
      const discountAmount = getCouponDiscount(price, amount, discountType);
      return accumulatedDiscount + discountAmount;
    },
    0
  );

  const totalPrice = price * quantity - totalDiscount * quantity;
  return Math.max(totalPrice, 0);
};

const getCouponDiscount = (
  price: number,
  discountValue: string,
  discountType: string
) => {
  switch (discountType) {
    case COUPON_DISCOUNT_TYPES.PERCENT:
    case COUPON_DISCOUNT_TYPES.RECURRING_PERCENT:
      return Math.max(price * (Number(discountValue) / 100), 0);
    case COUPON_DISCOUNT_TYPES.FIXED_PRODUCT:
    case COUPON_DISCOUNT_TYPES.RECURRING_FEE:
      return Number(discountValue);
    default:
      return 0;
  }
};

const getPayments = (coupon: CouponData) => {
  const { metaData } = coupon;
  if (metaData) {
    return Number(metaData[0].value);
  }
  return 0;
};

const getRecurringCoupons = (coupons: CouponData[]) =>
  coupons.flatMap((coupon) =>
    RECURRING_COUPONS.includes(coupon.discountType) ? coupon : []
  );

const getRecurringDiscounts = (
  productPrice: string,
  discount: DiscountConfig | null,
  coupons: CouponData[]
) => {
  const { discountedPrice, isRecurringDiscount } = discount ?? {};
  const price =
    isRecurringDiscount && discountedPrice
      ? discountedPrice
      : Number(productPrice);

  const recurringCoupons = getRecurringCoupons(coupons);
  if (recurringCoupons.length) {
    return {
      price: getCouponPrice(price, recurringCoupons),
      originalPrice: productPrice,
      payments: getPayments(recurringCoupons[0]),
    };
  } else if (discount) {
    return { price, originalPrice: productPrice };
  } else {
    return null;
  }
};
