import React, { createContext, useReducer, ReactNode, useContext } from "react";

export type CheckoutState = {
  canSubmit: boolean;
  isFormValid: boolean;
  termsAndConditionsAccepted: boolean;
  selectedPaymentMethod: null | string;
  formData: Record<string, string>;
  notes: string;
  formErrors: Record<string, string>;
  submissionErrors: string | null;
};

export type Action =
  | { type: "SET_FORM_VALID"; payload: boolean }
  | { type: "SET_FORM_DATA"; payload: Record<string, string> }
  | {
      type: "SET_ADDITIONAL_INFO";
      payload: { termsAndConditions: boolean; notes: string };
    }
  | { type: "SET_PAYMENT_METHOD"; payload: string }
  | { type: "SET_ERRORS"; payload: { submissionErrors: string | null } }
  | {
      type: "SAVE_ERRORED_FIELDS";
      payload: { erroredFields: Record<string, string> };
    };

type CheckoutContextType = {
  state: CheckoutState;
  dispatch: React.Dispatch<Action>;
};

const CheckoutContext = createContext<CheckoutContextType | undefined>(
  undefined
);

const initialState: CheckoutState = {
  canSubmit: false,
  isFormValid: false,
  termsAndConditionsAccepted: false,
  selectedPaymentMethod: null,
  notes: "",
  formData: {},
  formErrors: {},
  submissionErrors: null,
};

const reducer = (state: CheckoutState, action: Action): CheckoutState => {
  switch (action.type) {
    case "SET_FORM_VALID":
      return {
        ...state,
        isFormValid: action.payload,
        canSubmit: action.payload && state.termsAndConditionsAccepted,
      };
    case "SET_FORM_DATA":
      return {
        ...state,
        formData: action.payload,
      };
    case "SAVE_ERRORED_FIELDS":
      return {
        ...state,
        formErrors: action.payload.erroredFields,
      };
    case "SET_ADDITIONAL_INFO":
      return {
        ...state,
        termsAndConditionsAccepted: action.payload.termsAndConditions,
        notes: action.payload.notes ?? "",
        canSubmit: action.payload.termsAndConditions && state.isFormValid,
      };
    case "SET_PAYMENT_METHOD":
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      };
    case "SET_ERRORS": {
      return {
        ...state,
        submissionErrors: action.payload.submissionErrors,
      };
    }
    default:
      return state;
  }
};

const CheckoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <CheckoutContext.Provider value={{ state, dispatch }}>
      {children}
    </CheckoutContext.Provider>
  );
};

const useCheckoutContext = (): CheckoutContextType => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error("useCheckoutContext must be used within a StoreProvider");
  }

  return context;
};

export { CheckoutProvider, useCheckoutContext };
