import {
  CartResponseData,
  CartUpdateParams,
  FormattedCartItem,
  FormattedCartResponse,
  OrderResponseData,
  SubscriptionResponseData,
} from "../../models/cart";
import { fetchWPData, postWPData } from "../network";
import { formatCartItemsFromResponse } from "./cart.helpers";
import { formatOrderData } from "./formatOrderData";
import { formatSubscriptionData } from "./formatSubscriptionData";

export const getCartItems = async (): Promise<FormattedCartResponse> => {
  const cartItemsUri = `clean-edge/v2/cart/`;
  return await fetchWPData(cartItemsUri)
    .then((responseData) => {
      const { data } = responseData as unknown as CartResponseData;
      const formattedCartItems = formatCartItemsFromResponse(data);
      return formattedCartItems;
    })
    .catch((error) => {
      console.error("Error:", error);
      return {
        formattedCartItems: [] as FormattedCartItem[],
        discount: {},
      } as FormattedCartResponse;
    });
};

const REMOVE_ITEM_URL = "clean-edge/v2/cart/update?action=remove";
export const removeItemFromCart = async (
  removeItemParams: CartUpdateParams
): Promise<FormattedCartItem[]> => {
  return await postWPData(REMOVE_ITEM_URL, { ...removeItemParams })
    .then((responseData) => {
      const { data } = responseData as unknown as CartResponseData;
      const { formattedCartItems } = formatCartItemsFromResponse(data);
      return formattedCartItems;
    })
    .catch((error) => {
      console.error("Error:", error);
      return [] as FormattedCartItem[];
    });
};

export const UPDATE_ITEM_URL = "clean-edge/v2/cart/update?action=update";
export const updateItemInCart = async (
  updateItemParams: CartUpdateParams
): Promise<any> => {
  return await postWPData(UPDATE_ITEM_URL, { ...updateItemParams })
    .then((responseData) => {
      const { data } = responseData as unknown as CartResponseData;
      const formattedCartItems = formatCartItemsFromResponse(data);
      return formattedCartItems;
    })
    .catch((error) => {
      console.error("Error:", error);
      return [] as FormattedCartItem[];
    });
};

export const ADD_ITEM_URL = "clean-edge/v2/cart/update?action=add";
export const addItemToCart = async (
  addItemParams: CartUpdateParams
): Promise<any> => {
  return await postWPData(ADD_ITEM_URL, { ...addItemParams })
    .then((responseData) => {
      const { data } = responseData as unknown as CartResponseData;
      const formattedCartItems = formatCartItemsFromResponse(data);
      return formattedCartItems;
    })
    .catch((error) => {
      console.error("Error:", error);
      return [] as FormattedCartItem[];
    });
};

export const EMPTY_CART_URL = "clean-edge/v2/cart/empty";
export const emptyCart = async (): Promise<any> => {
  return await fetchWPData(EMPTY_CART_URL)
    .then((responseData) => {
      return responseData;
    })
    .catch((error) => {
      console.error("Error:", error);
      return [] as FormattedCartItem[];
    });
};

const FETCH_ORDER_URL = "clean-edge/v2/get-order-data";
export const getOrderData = async (
  orderId: number,
  orderKey: string
): Promise<any> => {
  return await postWPData(FETCH_ORDER_URL, { orderId, orderKey })
    .then((responseData) => {
      const { data, redirect } = responseData as unknown as OrderResponseData;

      if (redirect) {
        // redirect
      } else if (data) {
        const formattedCartItems = formatOrderData(data);
        return formattedCartItems;
      }
      return null;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

const RESUBSCRIPTION_URL = "clean-edge/v2/resubscribe";
export const getResubscriptionData = async (
  subscriptionId: number
): Promise<FormattedCartResponse> => {
  return await postWPData(RESUBSCRIPTION_URL, { subscriptionId })
    .then((responseData) => {
      const subscriptionData = formatSubscriptionData(
        responseData as SubscriptionResponseData
      );
      return subscriptionData;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};
