import { useMemo } from "react";
import { CtaButton } from "../../../../../components/cta-button/CtaButton";
import { useCheckoutContext } from "../../../context/checkout.context";
import { useHandleSubmit } from "./useHandleSubmit";
import { useCalculatePrice } from "../../cart-details-display/hooks/useCalculatePrice";
import { useCartTableData } from "../../cart-details-display/hooks/useCartTableData";
import { Tooltip } from "../../tooltip/ToolTip";

export const SubmitButton = () => {
  const handleSubmit = useHandleSubmit();

  const tableData = useCartTableData();
  const { total } = useCalculatePrice(tableData);
  const TITLE = total !== 0 ? "Pay Now" : "Complete Checkout";
  const { isDisabled, disabledReasons } = useSubmitDisabled();
  return (
    <div className="checkout-submit-section">
      <Tooltip
        isTooltipHidden={!isDisabled || disabledReasons.length === 0}
        content={
          <div>
            {disabledReasons.map((reason) => (
              <>
                <p className="reason-text">{reason}</p>
                <br />
              </>
            ))}
          </div>
        }
      >
        <CtaButton
          onClick={handleSubmit}
          title={TITLE}
          isDisabled={isDisabled}
        />
      </Tooltip>
    </div>
  );
};

const useSubmitDisabled = () => {
  const {
    state: { canSubmit, termsAndConditionsAccepted, formErrors },
  } = useCheckoutContext();
  const isDisabled = useMemo(() => !canSubmit, [canSubmit]);
  const disabledReasons = Object.values(formErrors).map((value) => value);
  if (!termsAndConditionsAccepted) {
    disabledReasons.push("Terms and conditions must be accepted");
  }

  return { disabledReasons, isDisabled };
};
