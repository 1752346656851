import { useCallback, useState } from "react";
import { login } from "./../../services/user/user";
import { LoginData } from "../../models/user";
import { useAppContext } from "../../context/app.context";

export const useHandleLogin = () => {
  const { dispatch } = useAppContext();
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const handleLogin = useCallback(
    async (loginData: LoginData) => {
      dispatch({
        type: "SET_LOADING",
        payload: { isLoading: true, loadingMessage: "Logging In" },
      });
      const response = await login(loginData);
      if (response.message === "Login successful" && response.userId) {
        setResponseMessage(null);
        // TODO - create a better method - reoading the nav rather than the whole page
        window.location.reload();
        dispatch({ type: "SET_USER", payload: response.userId });
      } else {
        setResponseMessage(response.message);
      }
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    },
    [setResponseMessage, dispatch]
  );
  return { responseMessage, handleLogin };
};
