import { Form, Formik } from "formik";
import { validateAllRequired } from "../../utils/form/validation";
import { LOGIN_FIELDS } from "./login.consts";
import { useMemo, useState } from "react";
import { FormFields } from "../../components/form-fields/FormFields";
import { FormError } from "../../components/form-fields/form-error/FormError";
import { CtaButton } from "../../components/cta-button/CtaButton";
import { isObjectEmpty } from "../../utils/isObjectEmpty";
import { useHandleLogin } from "./useHandleLogin";
import { LoginData } from "../../models/user";
import "./login.css";

interface LoginProps {
  message?: string;
}

export const Login = ({ message }: LoginProps) => {
  const { loginFormFields, initialValues } = useLoginFormFields();
  const [formValues, setFormValues] = useState({});
  const { handleLogin, responseMessage } = useHandleLogin();
  const onLoginSubmit = () => {
    handleLogin(formValues as LoginData);
  };
  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(true);
  return (
    <div className="login-container">
      <h3>Login</h3>
      <p className="login-message">{message}</p>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validate={(values) => {
          const requiredErrors = validateAllRequired(loginFormFields, values);
          if (isObjectEmpty(requiredErrors)) {
            setIsLoginDisabled(false);
          }
          setFormValues(values);
          return requiredErrors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ errors }) => (
          <Form>
            {Object.keys(loginFormFields).map((value: string, index) => {
              // @ts-ignore
              const fieldItem = loginFormFields[value];
              const fieldError = errors[value];
              return (
                <>
                  <FormFields
                    key={`${value} ${index}`}
                    fieldName={value}
                    field={fieldItem}
                  />
                  {fieldError && <FormError errorMessage={fieldError} />}
                </>
              );
            })}
          </Form>
        )}
      </Formik>
      <p className="response-message">{responseMessage}</p>
      <CtaButton
        title={"Login"}
        onClick={onLoginSubmit}
        isDisabled={isLoginDisabled}
      />
    </div>
  );
};

const useLoginFormFields = () => {
  const loginFormFields = useMemo(() => {
    return LOGIN_FIELDS;
  }, []);
  const initialValues = useMemo(
    () =>
      Object.keys(loginFormFields).reduce(
        (prev: Record<string, string>, curr: string) => {
          prev[curr] = "";
          return prev;
        },
        {}
      ),
    [loginFormFields]
  );
  return { initialValues, loginFormFields };
};
