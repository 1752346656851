import { Field, ErrorMessage } from "formik";
import { DropdownOption } from "../formFields.types";
import { REQUIRED } from "../formFields.consts";

interface DropdownInputProps {
  fieldName: string;
  label?: string;
  options: DropdownOption[];
  placeholder?: string;
  required?: boolean;
}
export const Dropdown = ({
  label,
  options,
  fieldName,
  required,
  placeholder,
}: DropdownInputProps) => {
  return (
    <div className="form-field-container">
      <label className="form-field-label">
        {label ?? fieldName}{" "}
        {required ? (
          <span className="form-field-required">{REQUIRED}</span>
        ) : (
          ""
        )}
      </label>
      <span className="woocommerce-input-wrapper">
        <Field
          className="select form-control"
          as="select"
          id={fieldName}
          name={fieldName}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options.map(({ value, label }) => (
            <option value={value}>{label}</option>
          ))}
        </Field>
      </span>

      <ErrorMessage name={fieldName} component="div" />
    </div>
  );
};
