import { useCallback } from "react";
import { useAppContext } from "../../../../context/app.context";
import { processPayment } from "../../../../services/checkout/checkout";
import { useCheckoutContext } from "../../context/checkout.context";
import { encryptData } from "../../../../utils/encryption";

const STOP_LOADING = 1000;
export const useProcessPayment = (orderId: number, orderKey: string) => {
  const {
    dispatch,
    state: { cartItems },
  } = useAppContext();

  const { dispatch: checkoutDispatch } = useCheckoutContext();

  return useCallback(
    async (cardDetails: any) => {
      dispatch({
        type: "SET_LOADING",
        payload: {
          isLoading: true,
          loadingMessage: "Processing payment please wait",
        },
      });
      const paymentPayload = {
        encryptedCardDetails: encryptData(cardDetails),
        orderId,
        orderKey,
      };
      const paymentData = await processPayment(paymentPayload);
      const { errorMessage, redirect } = paymentData;
      if (errorMessage) {
        window.scrollTo(0, 0);
      }
      const cardErrorMessage = displayCardErrors(errorMessage);
      checkoutDispatch({
        type: "SET_ERRORS",
        payload: { submissionErrors: cardErrorMessage ?? null },
      });
      if (redirect) {
        window.location.href = redirect;
      }
      setTimeout(() => {
        dispatch({
          type: "SET_LOADING",
          payload: { isLoading: false, loadingMessage: "Loading please wait" },
        });
      }, STOP_LOADING);
    },
    [cartItems, dispatch]
  );
};

const displayCardErrors = (errorMessage: string) => {
  if (errorMessage) {
    return errorMessage.length ? errorMessage : "Error Processing Payment";
  }
  return null;
};
