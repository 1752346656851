import { NOTICE_TYPES } from "./notice.consts";
import "./notice.css";

interface NoticeProps {
  type?: string;
  children: React.ReactNode;
}
export const Notice = ({
  type = NOTICE_TYPES.NOTICE,
  children,
}: NoticeProps) => {
  const iconClassName =
    type === NOTICE_TYPES.NOTICE
      ? "fa-solid fa-circle-info"
      : "fa-solid fa-circle-exclamation";
  return (
    <div className={`notice-wrapper ${type}-notice`}>
      <i className={iconClassName} />
      {children}
    </div>
  );
};
