import { CouponData } from "../../../../../models/cart";
import { COUPON_DISCOUNT_TYPES } from "../../../checkout.consts";

export const getAppliedCouponsContent = (coupon: CouponData) => {
  const discountValue = getDiscount(coupon.amount, coupon.discountType);
  const couponCode = coupon.code;
  const couponDetails = getCouponDetails(coupon);
  return { couponDetails, couponCode, discountValue };
};

const getDiscount = (discountAmount: string, discountType: string) => {
  switch (discountType) {
    case COUPON_DISCOUNT_TYPES.PERCENT:
      return `${discountAmount}% off applicable products`;
    case COUPON_DISCOUNT_TYPES.FIXED_PRODUCT:
      return `$${Number(discountAmount).toFixed(2)} off applicable products`;
    case COUPON_DISCOUNT_TYPES.FIXED_CART:
      return `$${Number(discountAmount).toFixed(2)}`;
    case COUPON_DISCOUNT_TYPES.RECURRING_FEE:
      return `$${Number(discountAmount).toFixed(2)}`;
    case COUPON_DISCOUNT_TYPES.RECURRING_PERCENT:
      return `${discountAmount}%`;
    default:
      return `${discountAmount}`;
  }
};

const getCouponDetails = (coupon: CouponData) => {
  const { description, metaData } = coupon;
  const paymentCount = metaData?.find(
    ({ key }) => key === "_wcs_number_payments"
  );
  if (paymentCount && paymentCount.value !== "") {
    const paymentDetails = `Discount will be applied for up to ${paymentCount.value} payments`;
    return `${description}${description ? "\n" : ""} ${paymentDetails}`;
  }
  return description;
};
