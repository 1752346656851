import { Carousel } from "../../../../components/carousel/Carousel";
import { Product } from "../../../../models/products/products.types";
import { useFeaturedProductData } from "./useFeaturedProductData";
import "./featuredProducts.css";
import { useAddProduct } from "../../hooks/useAddProduct";

export const FeaturedProducts = () => {
  const products = useFeaturedProductData();
  const items = products
    ? products?.map((product) => <FeaturedProductCard productData={product} />)
    : [];
  return (
    <div>
      <Carousel title={"Featured Products"} items={items} />
    </div>
  );
};

interface FeaturedProductCardProps {
  productData: Product;
}
const FeaturedProductCard = ({ productData }: FeaturedProductCardProps) => {
  const addProductToCart = useAddProduct();
  return (
    <div className="featured-product-container">
      <a href="/product-category/physical/">
        <img
          className="featured-product-thumbnail"
          src={productData.thumbnailUrl}
          alt=""
        />
      </a>
      <div className="featured-product-details">
        <h4 className="featured-product-title">{productData.title}</h4>
        <span className="featured-product-price">${productData.price}</span>
        <div className="featured-product-button">
          <button
            className="shop-btn"
            onClick={() => addProductToCart(productData.id)}
          >
            <i className="fa-thin fa-cart-shopping" />
            Buy
          </button>
        </div>
      </div>
    </div>
  );
};
