import { Product } from "../../../../../models/products/products.types";
import { getFirstNumberFromString } from "../../../../../utils/firstNumberFromString";
import { formatDate } from "../../../../../utils/formatDate";

export const getIsRecurring = (selectedProduct: Product) =>
  selectedProduct.title.indexOf("Membership") !== -1 &&
  selectedProduct.categories.length;

export const getRecurringDate = (durationMonths: number) => {
  const date = new Date();
  date.setMonth(date.getMonth() + durationMonths);
  return formatDate(date);
};

export const getMembershipDuration = (selectedProduct: Product) => {
  if (
    selectedProduct.title.indexOf("Membership") !== -1 &&
    selectedProduct.categories.length
  ) {
    const duration = getFirstNumberFromString(selectedProduct.title);
    return duration;
  }
  return null;
};
