import { useMemo } from "react";
import { ADDITIONAL_INFO, TERMS_AND_CONDITIONS } from "../../checkout.consts";
import { TermsAndConditions } from "./TermsAndConditions";

export const useAdditionalInformationFields = () => {
  return useMemo(() => {
    const termsAndConditions = {
      ...TERMS_AND_CONDITIONS,
      component: <TermsAndConditions />,
    };
    return { ...ADDITIONAL_INFO, termsAndConditions };
  }, []);
};
