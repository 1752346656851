import { useCallback, useState } from "react";
import { applyCoupon } from "../../../../services/checkout/checkout";
import { useAppContext } from "../../../../context/app.context";

export const useHandleApplyCoupon = (couponCode: string) => {
  const [message, setMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const {
    dispatch,
    state: { coupons },
  } = useAppContext();
  const applyCouponCallback = useCallback(async () => {
    const result = await applyCoupon({ couponCode });
    if (result.errorMessage) {
      setMessage(result.errorMessage);
    } else if (result.success) {
      setMessage(`Coupon "${couponCode}" has been successfully applied`);
      setIsSuccess(true);
      dispatch({
        type: "UPDATE_COUPONS",
        payload: { coupons: result.coupons ?? [] },
      });
    }
  }, [couponCode, dispatch, coupons]);

  return { message, isSuccess, applyCouponCallback };
};
