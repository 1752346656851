import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

interface CarouselProps {
  title: string;
  items: JSX.Element[];
}

export const Carousel = ({ title, items }: CarouselProps) => {
  return (
    <div className="carousel-container">
      <h3 className="section-header">{title}</h3>
      {items.length && (
        <Splide
          options={{
            type: "loop",
            rewindByDrag: true,
            rewind: true,
            width: "100%",
            gap: "1rem",
            focus: "center",
            perPage: 3,
            start: 3,
            clones: 6,
            arrows: true,
            pagination: true,
            trimSpace: "move",
          }}
          hasTrack={false}
          aria-label={title}
        >
          <SplideTrack>
            {items.map((item, index) => (
              <SplideSlide style={{ width: "33%" }} key={index}>
                {item}
              </SplideSlide>
            ))}
          </SplideTrack>
        </Splide>
      )}
    </div>
  );
};
