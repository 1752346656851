export const getFirstNumberFromString = (
  inputString: string
): number | null => {
  const regex = /\d+/;
  const match = inputString.match(regex);
  if (match) {
    const matchedDigits = match[0];
    const numberValue = parseInt(matchedDigits, 10);
    return numberValue;
  }
  return null;
};
