import { useMemo } from "react";
import { FormattedCartItem } from "../../../../models/cart";
import {
  CHECKOUT_FIELD_ORDER,
  DEFAULT_CHECKOUT_FORM_FIELDS,
  NON_PERSISTENT_FIELDS,
  PHYSICAL_PRODUCT_FORM_FIELDS,
  VEHICLE_REGISTRATION,
  WASHCODE_FORM_FIELDS,
} from "../../checkout.consts";
import { Product } from "../../../../models/products/products.types";
import {
  CHECKOUT_FORM_KEY,
  INDIVIDUAL_WASH,
  MEMBERSHIP_PRODUCT_CATEGORY_NAMES,
} from "../../../../models/products/products.consts";
import { SiteLocation } from "../../checkout.types";
import { useSavedFormData } from "../../hooks/useSavedFormData";
import { reorderObjectKeys } from "../../../../utils/reorderObjectKeys";
import { FormField } from "../../../../components/form-fields/formFields.types";

export const useCheckoutFormFields = (
  cartItems: FormattedCartItem[],
  products: Product[],
  locations: SiteLocation[]
) => {
  const { savedFormData, saveFormData } = useSavedFormData(CHECKOUT_FORM_KEY);
  const checkoutFormFields = useMemo(() => {
    const formFields = DEFAULT_CHECKOUT_FORM_FIELDS;
    const formattedLocation = formatLocation(locations);
    const washcodeFields = getWashcodeFields(cartItems, products);
    const membershipFields = getMembershipFields(cartItems, products);
    const checkoutFields = {
      ...formFields,
      location: formattedLocation,
      ...washcodeFields,
      ...membershipFields,
    };
    return reorderObjectKeys(checkoutFields, CHECKOUT_FIELD_ORDER) as Record<
      string,
      FormField
    >;
  }, [cartItems, products, locations]);
  const initialValues = useMemo(
    () =>
      Object.keys(checkoutFormFields).reduce(
        (prev: Record<string, string>, curr: string) => {
          prev[curr] = "";
          if (
            savedFormData &&
            savedFormData[curr] &&
            !NON_PERSISTENT_FIELDS.includes(curr)
          ) {
            prev[curr] = savedFormData[curr];
          }
          if (savedFormData && savedFormData[getSubscriptionDataKey(curr)]) {
            prev[curr] = savedFormData[getSubscriptionDataKey(curr)];
          }
          return prev;
        },
        {}
      ),
    [checkoutFormFields, savedFormData]
  );
  return { checkoutFormFields, initialValues, saveFormData };
};

const formatLocation = (locations: SiteLocation[]) => {
  return {
    ...DEFAULT_CHECKOUT_FORM_FIELDS.location,
    options: locations.map((location) => ({
      value: location.id,
      label: `Tunnel Express ${location.title} ${location.address}`,
    })),
  };
};
const getWashcodeFields = (
  cartItems: FormattedCartItem[],
  products: Product[]
) => {
  for (const cartItem of cartItems) {
    const { productId } = cartItem;
    const selectedProduct = products.find(
      (product) => product.id === productId
    );
    if (selectedProduct) {
      if (
        selectedProduct.categories.some(
          (category) => category === INDIVIDUAL_WASH
        )
      ) {
        return WASHCODE_FORM_FIELDS;
      }
    }
  }
  return {};
};

const getMembershipFields = (
  cartItems: FormattedCartItem[],
  products: Product[]
) => {
  for (const cartItem of cartItems) {
    const { productId } = cartItem;
    const selectedProduct = products.find(
      (product) => product.id === productId
    );
    if (selectedProduct) {
      if (
        selectedProduct.categories.some((category) =>
          MEMBERSHIP_PRODUCT_CATEGORY_NAMES.includes(category)
        )
      ) {
        return { ...VEHICLE_REGISTRATION, ...PHYSICAL_PRODUCT_FORM_FIELDS };
      }
    }
  }
  return {};
};

function getSubscriptionDataKey(key: string): string {
  // Capitalize the first letter of the key
  const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
  // Add the prefix 'subscription' to the key
  return "subscription" + capitalizedKey;
}

// const useGetDOB = () => {
//   const {
//     state: { userId },
//   } = useAppContext();
//   return useMemo(() => {
//     if (userId) {
//     }
//   }, [userId]);
// };
