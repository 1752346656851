import "./hero.css";
interface HeroProps {
  heroTitle: string;
  heroImageUri: string;
}

export const Hero = ({ heroTitle, heroImageUri }: HeroProps) => {
  return (
    <div
      className="hero-img hero-container"
      style={{ backgroundImage: `url(${heroImageUri})` }}
    >
      <h1 className="def-hero-title hero-container-title">{heroTitle}</h1>
    </div>
  );
};
