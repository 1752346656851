import CryptoJS from "crypto-js";

export const encryptData = (data: Record<string, unknown>) => {
  // Convert data to a JSON string
  const dataString = JSON.stringify(data);
  const privateKeyWA = process.env.REACT_APP_SECRET_KEY ?? "";

  let iVWA = CryptoJS.enc.Latin1.parse("\0".repeat(16)); // 3. apply a 16 bytes zero IV (as WordArray)

  // Encrypt the JSON string
  const encryptedData = CryptoJS.AES.encrypt(dataString, privateKeyWA, {
    iv: iVWA,
  });
  return encryptedData.toString();
};
