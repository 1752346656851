import { useMemo } from "react";
import { FormattedCartItem } from "../../../models/cart";
import { Product } from "../../../models/products/products.types";
import { MULTI_MEMBERSHIP_DISCOUNT } from "../checkout.consts";

export const useApplyDiscounts = (
  cartItems: FormattedCartItem[],
  products: Product[],
  discounts: Record<string, boolean> | undefined
) =>
  useMemo(() => {
    // TODO - allow for multiple discounts
    if (discounts) {
      const discountKeys = Object.keys(discounts);
      if (discountKeys[0] === MULTI_MEMBERSHIP_DISCOUNT.key) {
        return multiVehicleDiscount(cartItems, products);
      }
    }
    return cartItems;
  }, [cartItems, discounts, products]);

const multiVehicleDiscount = (
  cartItems: FormattedCartItem[],
  products: Product[]
) =>
  cartItems.map((item) => {
    const lineItemProductData = products.find(
      (product) => product.id === item.productId
    );

    if (lineItemProductData) {
      const discount =
        lineItemProductData.attributes[
          MULTI_MEMBERSHIP_DISCOUNT.productAttribute
        ];

      // @ts-ignore
      if (discount && discount.value) {
        return {
          ...item,
          discount: {
            discountType: MULTI_MEMBERSHIP_DISCOUNT.key,
            // @ts-ignore
            discountedPrice: Number(discount.value),
            isRecurringDiscount: MULTI_MEMBERSHIP_DISCOUNT.recurring,
          },
        };
      }
    }
    return item;
  });
