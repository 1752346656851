import { useCallback, useEffect, useMemo, useState } from "react";
import RadioButtonGroup from "../../../../components/radio-button-group/RadioButtonGroup";
import {
  PaymentGatewayData,
  UserTokenData,
} from "../../../../models/checkout/checkout.types";
import { usePaymentData } from "./usePaymentData";
import { SubmitButton } from "./submit/SubmitButton";
import "./payment.css";
import { useCheckoutContext } from "../../context/checkout.context";
import { Loading } from "../../../../components/loading/Loading";
import { DEFAULT_PAYMENT } from "../../checkout.consts";
import { useCalculatePrice } from "../cart-details-display/hooks/useCalculatePrice";
import { useCartTableData } from "../cart-details-display/hooks/useCartTableData";

export const Payment = () => {
  const { data, isLoading } = usePaymentData();

  const tableData = useCartTableData();
  const { total } = useCalculatePrice(tableData);
  return (
    <div id="payment" className="checkout-payment-container">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {total !== 0 &&
            data?.gateways.map((gateway) => <GatewayInfo gateway={gateway} />)}
          {total !== 0 && <UserTokenInfo tokenData={data.userTokenData} />}
          <SubmitButton />
        </>
      )}
    </div>
  );
};

interface GatewayInfoProps {
  gateway: PaymentGatewayData;
}
const GatewayInfo = ({ gateway }: GatewayInfoProps) => {
  const { title, cardTypes, testmode } = gateway;
  return (
    <div>
      <label>{title}</label>
      {testmode && <p>Test Mode is on</p>}
      <div className="card-image-container">
        {cardTypes.map(({ name, iconUrl }) => (
          <img className="card-image" src={iconUrl} alt={name} />
        ))}
      </div>
    </div>
  );
};

interface UserTokenInfoProps {
  tokenData: UserTokenData[];
}
const UserTokenInfo = ({ tokenData }: UserTokenInfoProps) => {
  const options = useMemo(() => {
    const tokens = tokenData.map((data, index) => ({
      id: `${index}`,
      label: data.name,
    }));
    const DEFAULT_OPTION = [
      { id: DEFAULT_PAYMENT, label: "Use a new credit card" },
    ];
    return [...tokens, ...DEFAULT_OPTION];
  }, [tokenData]);

  const initialSelection = tokenData.findIndex(
    ({ isDefault }) => isDefault === true
  );

  const [selectedOptionId, setSelectedOptionId] = useState<string>(
    `${initialSelection !== -1 ? initialSelection : DEFAULT_PAYMENT}`
  );

  const { dispatch } = useCheckoutContext();
  const handleOptionChange = useCallback(
    (optionId: string) => {
      setSelectedOptionId(optionId);
    },
    [setSelectedOptionId]
  );

  useEffect(() => {
    dispatch({ type: "SET_PAYMENT_METHOD", payload: selectedOptionId });
  }, [dispatch, selectedOptionId]);

  return (
    <div className="payment-options">
      <RadioButtonGroup
        options={options}
        selectedOptionId={selectedOptionId}
        onOptionChange={handleOptionChange}
      />
    </div>
  );
};
