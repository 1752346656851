import { FORM_FIELDS } from "../../components/form-fields/formFields.consts";

export const LOGIN_FIELDS = {
  username: { label: "Username or email", required: true },
  password: { label: "Password", required: true, password: true },
  rememberMe: {
    required: false,
    label: "Remember Me",
    inputType: FORM_FIELDS.CHECKBOX,
  },
};
