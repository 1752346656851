interface FormErrorProps {
  errorMessage: string;
}
export const FormError = ({ errorMessage }: FormErrorProps) => {
  return (
    <span className="form-error">
      <strong>{errorMessage}</strong>
    </span>
  );
};
