import { useQuery } from "react-query";
import { fetchPaymentData } from "../../../../services/checkout/checkout";

import { PaymentData } from "../../../../models/checkout/checkout.types";

export const usePaymentData = (): { data: PaymentData; isLoading: boolean } => {
  const paymentData = useQuery<PaymentData>(["fetch-payment-data"], () =>
    fetchPaymentData()
  );
  if (paymentData.data) {
    return { data: paymentData.data, isLoading: paymentData.isLoading };
  }
  return { data: { gateways: [], userTokenData: [] }, isLoading: true };
};
