import { useLocation } from "react-router-dom";
import { Product } from "../../../models/products/products.types";
import { useMemo } from "react";
import { productToCartItem } from "../../../services/cart/cart.helpers";

export const useGetProductsFromUrl = (products: Product[]) => {
  const location = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const productId = searchParams.get("productId");
    if (productId) {
      const selectedProduct = products.find(
        ({ id }) => Number(id) === Number(productId)
      );
      if (selectedProduct) {
        return productToCartItem(selectedProduct);
      }
    }
    return null;
  }, [location, products]);
};
