import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Checkout } from "./views/checkout/Checkout";
import { NotFound } from "./views/not-found/NotFound";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppProvider } from "./context/app.context";
import { usePathRoot } from "./hooks/usePathRoot";
import { CheckoutProvider } from "./views/checkout/context/checkout.context";
import "./App.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 15,
    },
  },
});

function App() {
  const pathRoot = usePathRoot();
  return (
    <AppProvider>
      <CheckoutProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Routes>
              <Route path={`${pathRoot}/te-checkout/`} Component={Checkout} />
              <Route Component={NotFound} />
            </Routes>
          </Router>
        </QueryClientProvider>
      </CheckoutProvider>
    </AppProvider>
  );
}

export default App;
