import { Hero } from "../../../components/hero/Hero";
import { Loading } from "../../../components/loading/Loading";
import { LoadingOverlay } from "../../../components/loading/LoadingOverlay";
import "../checkout.css";
import { CardHandler } from "./card-handler/CardHandler";
import { CartDetailsDisplay } from "../components/cart-details-display/CartDetailsDisplay";
import { useCalculatePrice } from "../components/cart-details-display/hooks/useCalculatePrice";
import { useCartTableData } from "../components/cart-details-display/hooks/useCartTableData";
import { ErrorMessages } from "../components/error-messages/ErrorMessages";
import { useCheckoutPageContent } from "../hooks/useCheckoutPageContent";
import { useOrderCartItems } from "./useOrderCartItems";
import { useAppContext } from "../../../context/app.context";

interface OrderPayProps {
  orderId: number;
  orderKey: string;
}
export const OrderPay = ({ orderId, orderKey }: OrderPayProps) => {
  const {
    data: { heroImageUri },
  } = useCheckoutPageContent();
  const { isLoading, displayContent } = useOrderCartItems(orderId, orderKey);
  const totalDue = useTotalDue();
  return (
    <div className="checkout-container order-pay-container">
      <LoadingOverlay />
      <Hero heroTitle={"Pay for order"} heroImageUri={heroImageUri ?? ""} />
      <ErrorMessages />
      {displayContent !== false && (
        <>
          <div className="order-details-header">
            <h2>Order Number: {orderId} |</h2>
            {!isLoading && totalDue && (
              <h2>Total Due Now: ${totalDue.toFixed(2)} |</h2>
            )}
          </div>
          <div className="order-details-subheader">
            <span>Pay securely with NAB payment gateway</span>
            <span>Credit cards accepted: Visa, Mastercard</span>
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <div className="order-pay-content">
              <CartDetailsDisplay readOnly />
              <CardHandler orderId={orderId} orderKey={orderKey} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const useTotalDue = () => {
  const {state : {coupons}} = useAppContext()
  const cartData = useCartTableData();
  const { total } = useCalculatePrice(cartData, coupons);
  return total;
};
