import { OrderData, FormattedCartItem } from "../../models/cart";
import { snakeToCamel } from "../../utils/snakeToCamel";
import { formatAppliedCoupons } from "./formatAppliedCoupons";

export const formatOrderData = (orderData: OrderData) => {
  const formattedCartItems: FormattedCartItem[] = [];

  const { discount, status } = orderData;

  const formattedDiscount = snakeToCamel(discount) as Record<string, boolean>;
  const responseItems = orderData.items;
  const appliedCoupons: string[] = [];
  responseItems.forEach((item) => {
    const { total, quantity, productId, id, metaData } = item;
    const couponsRedeemed = metaData.find(
      (data) => data.key === "coupons_redeemed"
    );
    if (couponsRedeemed) {
      appliedCoupons.push(couponsRedeemed.value);
    }
    const cartItem: FormattedCartItem = {
      lineTotal: Number(total),

      quantity,
      productId,
      key: String(id),
    };
    formattedCartItems.push(cartItem);
  });

  const coupons = formatAppliedCoupons(appliedCoupons);
  return {
    formattedCartItems,
    status,
    discount: formattedDiscount,
    coupons,
  };
};
