export const FORM_FIELDS = {
  TEXT: "TEXT",
  DROPDOWN: "DROPDOWN",
  CHECKBOX: "CHECKBOX",
  TEXT_AREA: "TEXT_AREA",
};

export const REQUIRED = " *";

export const TEXT_INPUT_TYPES = {
  PASSWORD: "password",
  TEXT: "text",
  NUMERIC: "numeric",
};
