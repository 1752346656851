import { useMemo } from "react";
import { CartTableData } from "../cartDetailsDisplay.types";
import { CouponData } from "../../../../../models/cart";
import { COUPON_DISCOUNT_TYPES } from "../../../checkout.consts";

export const useCalculatePrice = (
  cartTableData: CartTableData[],
  coupons: CouponData[] = []
): { subtotal: number; total: number } => {
  return useMemo(() => {
    const cartTotalCoupons = coupons.flatMap((coupon) =>
      coupon.discountType === COUPON_DISCOUNT_TYPES.FIXED_CART ? coupon : []
    );
    const total = cartTableData.reduce((accumulator, currentItem) => {
      return (
        accumulator +
        (currentItem?.discount?.discountPriceValue ?? currentItem.lineTotal)
      );
    }, 0);

    const totalDiscount = cartTableData.reduce((accumulator, currentItem) => {
      return accumulator + (currentItem?.discount?.discountPriceValue ?? 0);
    }, 0);
    const totalCouponDiscount = cartTotalCoupons.reduce(
      (accumulator, currentCoupon) => {
        return accumulator + Number(currentCoupon.amount);
      },
      0
    );
    const withCouponsApplied = Math.max(
      total - totalCouponDiscount, 0
    );
    return { subtotal: withCouponsApplied, total: withCouponsApplied };
  }, [cartTableData, coupons]);
};
