import { useMemo } from "react";

export const usePathRoot = () =>
  useMemo(() => {
    const hostname = window.location.hostname;
    if (hostname.indexOf("localhost") !== -1) {
      return "/tunnel-express";
    }
    return "";
  }, []);
