import { createPortal } from "react-dom";
import { useCartMenuItems } from "./CartMenuItems";
import { useAppContext } from "../../../../context/app.context";
import { useMemo } from "react";

export const ExternalCheckoutUpdates = () => {
  const {
    state: { cartItems, products, isLoadingCart },
  } = useAppContext();

  const menuItemInner = useMemo(() => {
    const inner = window.document.getElementById("cart-menu");
    if (inner && !isLoadingCart) {
      inner.innerHTML = "";
    }
    return inner;
  }, [isLoadingCart]);
  const menuData = useCartMenuItems(cartItems, products);

  if (!products || isLoadingCart) {
    return null;
  }
  const cartCountSpan = window.document.getElementById("cart-count");
  const cartItemsCount = cartItems.reduce((accumulator, current) => {
    return Number(accumulator) + Number(current.quantity);
  }, 0);
  if (cartCountSpan) {
    cartCountSpan.innerHTML = "";
    cartCountSpan.textContent = "";
    cartCountSpan.textContent = cartItemsCount.toString();
  }

  return (
    <>{menuItemInner && createPortal(<div>{menuData}</div>, menuItemInner)}</>
  );
};
