import "./../formFields.css";

interface FormMessageProps {
  message: string;
}
export const FormMessage = ({ message }: FormMessageProps) => {
  return (
    <span className="form-message">
      <strong>{message}</strong>
    </span>
  );
};
