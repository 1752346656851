import { useAppContext } from "../../../../context/app.context";
import { useCalculatePrice } from "./hooks/useCalculatePrice";
import "./cartDetailsDisplay.css";
import { CouponsApplied } from "./coupons-applied/CouponsApplied";
import { RecurringTotals } from "./recurring-totals/RecurringTotals";
import { useCartTableData } from "./hooks/useCartTableData";
import { CartTableItems } from "./cart-table-items/CartTableItems";

interface CartDetailsDisplayProps {
  readOnly?: boolean;
}

export const CartDetailsDisplay = ({
  readOnly = false,
}: CartDetailsDisplayProps) => {
  const {
    state: { coupons },
  } = useAppContext();

  const tableData = useCartTableData();
  const { subtotal, total } = useCalculatePrice(tableData, coupons);
  return (
    <div className="order-details-summary">
      <h3 className="section-header">Your Order</h3>
      <table className="cart-details-table shop_table woocommerce-checkout-review-order-table ">
        <thead>
          <tr>
            <th className="cart-details-table-header">Product</th>
            <th className="cart-details-table-header">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <CartTableItems tableData={tableData} readOnly={readOnly} />
          <CouponsApplied coupons={coupons} />
        </tbody>
        <RecurringTotals tableItems={tableData} />
        <tfoot>
          <tr className="cart-subtotal">
            <td>
              <strong>Subtotal</strong>
            </td>
            <td>
              <span className="woocommerce-Price-amount amount">
                <bdi>
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  {subtotal.toFixed(2)}
                </bdi>
              </span>
            </td>
            <td></td>
          </tr>
          <tr className="order-total">
            <td>
              <strong>Total</strong>
            </td>
            <td>
              <strong>
                <span className="woocommerce-Price-amount amount">
                  <bdi>
                    <span className="woocommerce-Price-currencySymbol">$</span>
                    {total.toFixed(2)}
                  </bdi>
                </span>
              </strong>{" "}
            </td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
