import { useState, useEffect } from "react";
import { Hero } from "../../../components/hero/Hero";
import { Loading } from "../../../components/loading/Loading";
import { LoadingOverlay } from "../../../components/loading/LoadingOverlay";
import { useAppContext } from "../../../context/app.context";
import { AdditionalInformation } from "../components/additional-information/AdditionalInformation";
import { CartDetailsDisplay } from "../components/cart-details-display/CartDetailsDisplay";
import { CheckoutForm } from "../components/checkout-form/CheckoutForm";
import { Coupon } from "../components/coupon/Coupon";
import { ErrorMessages } from "../components/error-messages/ErrorMessages";
import { ExternalCheckoutUpdates } from "../components/external-checkout-updates/ExternalCheckoutUpdates";
import { FeaturedProducts } from "../components/featured-products/FeaturedProducts";
import { NoCheckoutItems } from "../components/no-checkout-items/NoCheckoutItems";
import { Payment } from "../components/payment/Payment";
import { ReturningCustomer } from "../components/returning-customer/ReturningCustomer";
import { useCartItems } from "../hooks/useCartItems";
import { useCheckoutPageContent } from "../hooks/useCheckoutPageContent";
import { CHECKOUT_DISPLAY_TIMEOUT } from "../checkout.consts";

export const CheckoutPage: React.FC = () => {
  const {
    state: { userId, products, cartItems },
  } = useAppContext();
  const {
    data: { locations, heroImageUri, userId: fetchedUserId },
    isLoading: loadingPageContent,
  } = useCheckoutPageContent();
  const { isLoading } = useCartItems();

  const [isLoadingCompleted, setIsLoadingCompleted] = useState<boolean>(false);
  useEffect(() => {
    if (isLoading && loadingPageContent) {
      setTimeout(() => {
        setIsLoadingCompleted(true);
      }, CHECKOUT_DISPLAY_TIMEOUT);
    }
  }, [isLoading]);

  return (
    <>
      <ExternalCheckoutUpdates />
      <div className="checkout-container">
        <Hero heroTitle={"Checkout"} heroImageUri={heroImageUri ?? ""} />
        <div className="checkout-content-container">
          <LoadingOverlay />
          {!isLoadingCompleted ? (
            <Loading />
          ) : cartItems && cartItems.length === 0 && isLoadingCompleted ? (
            <NoCheckoutItems />
          ) : (
            <>
              {!userId && !fetchedUserId && isLoadingCompleted && (
                <ReturningCustomer />
              )}
              <Coupon />
              <ErrorMessages />
              <CheckoutForm
                products={products}
                cartItems={cartItems}
                locations={locations}
              />
              <FeaturedProducts />
              <AdditionalInformation />
              <CartDetailsDisplay />
              <Payment />
            </>
          )}
        </div>
      </div>
    </>
  );
};
