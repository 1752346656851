import { useQuery } from "react-query";
import { getProducts } from "../../../services/products/products";
import {
  getCartItems,
  getResubscriptionData,
} from "../../../services/cart/cart";
import { FormattedCartResponse } from "../../../models/cart";
import { Product } from "../../../models/products/products.types";
import { useAppContext } from "../../../context/app.context";
import { useEffect, useMemo } from "react";
import { useGetProductsFromUrl } from "./useGetProductsFromUrl";
import { useApplyDiscounts } from "./useApplyDiscounts";
import { useAddProduct } from "./useAddProduct";
import { productIsInCart } from "../../../services/cart/cart.helpers";
import { useIsResubscribing } from "./useIsResubscribing";
import { useCheckoutContext } from "../context/checkout.context";
import { useSavedFormData } from "./useSavedFormData";
import { CHECKOUT_FORM_KEY } from "../../../models/products/products.consts";

export const useCartItems = () => {
  // Fetch data
  const products = useQuery<Product[]>(["products"], () => getProducts());
  const { dispatch } = useAppContext();
  const { data, isLoading } = useFetchCartData();
  // Format products from url and discounts
  const addProduct = useAddProduct();
  const urlProduct = useGetProductsFromUrl(products.data ?? []);
  const updatedCartItems = useMemo(() => {
    const cart = [...(data?.formattedCartItems ?? [])];
    if (
      urlProduct &&
      !productIsInCart(urlProduct.productId, cart) &&
      !isLoading
    ) {
      addProduct(urlProduct.productId);
    }

    return cart;
  }, [urlProduct, data?.formattedCartItems, isLoading]);

  const discountsApplied = useApplyDiscounts(
    updatedCartItems,
    products?.data ?? [],
    data?.discount
  );

  useEffect(() => {
    if (isLoading || !data?.formattedCartItems) {
      dispatch({ type: "IS_LOADING_CART", payload: isLoading });
    }
  }, [isLoading]);
  // return and set in state
  useEffect(() => {
    if (products.data && data?.formattedCartItems) {
      dispatch({ type: "SET_PRODUCTS", payload: products.data });
      dispatch({
        type: "UPDATE_CART",
        payload: { cartItems: discountsApplied },
      });

      dispatch({
        type: "UPDATE_COUPONS",
        payload: { coupons: data.coupons ?? [] },
      });
      dispatch({ type: "IS_LOADING_CART", payload: isLoading });
    }
  }, [products.data, discountsApplied, dispatch, data?.coupons]);

  if ((products.data && data?.formattedCartItems, data?.coupons)) {
    return {
      products: products.data ?? [],
      cartItems: discountsApplied,
      isLoading: false,
    };
  } else {
    return { products: [], cartItems: [], isLoading: true };
  }
};

const useFetchCartData = () => {
  const isResubscribing = useIsResubscribing();
  const { data, isLoading } = useQuery<FormattedCartResponse>(
    ["cart-items", isResubscribing],
    () =>
      isResubscribing && isResubscribing.subscriptionId
        ? getResubscriptionData(isResubscribing?.subscriptionId)
        : getCartItems()
  );

  // @ts-ignore
  const { formData, errorMessage } = data ?? {};
  useSetFormData(formData);
  useSetFormErrors(errorMessage);

  return { data, isLoading };
};

const useSetFormErrors = (errorMessage: string | undefined) => {
  const { dispatch } = useCheckoutContext();
  useEffect(() => {
    if (errorMessage) {
      dispatch({
        type: "SET_ERRORS",
        payload: { submissionErrors: errorMessage },
      });
    }
  }, [dispatch, errorMessage]);
};

const useSetFormData = (formData: any) => {
  const { saveFormData } = useSavedFormData(CHECKOUT_FORM_KEY);
  useEffect(() => {
    if (formData) {
      saveFormData(formData);
    }
  }, [formData, saveFormData]);
};
