import { Form, Formik } from "formik";
import { PAYMENT_FIELDS, SPECIAL_CARD_FIELDS } from "../../checkout.consts";
import { validateAllRequired } from "../../../../utils/form/validation";
import { isObjectEmpty } from "../../../../utils/isObjectEmpty";
import { FormFields } from "../../../../components/form-fields/FormFields";
import { FormMessage } from "../../../../components/form-fields/form-message/formMessage";
import { FormError } from "../../../../components/form-fields/form-error/FormError";
import { CtaButton } from "../../../../components/cta-button/CtaButton";
import { useMemo, useState } from "react";
import { useProcessPayment } from "./useProcessPayment";

interface CardHandlerProps {
  orderId: number;
  orderKey: string;
}
export const CardHandler = ({ orderId, orderKey }: CardHandlerProps) => {
  const cardImgUrl = "";
  const handleSubmit = useProcessPayment(orderId, orderKey);

  const cardFormData = useMemo(() => {
    const data = PAYMENT_FIELDS;
    const years = [];
    const startYear = 2024;

    for (let i = 0; i < 30; i++) {
      const year = startYear + i;
      years.push({ value: year.toString(), label: year.toString() });
    }

    data.expiryYear = { ...data.expiryYear, options: years };
    return data;
  }, []);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  return (
    <div className="card-handler">
      <Formik
        initialValues={{}}
        validateOnBlur
        validateOnMount
        validate={(values) => {
          const errors = {} as Record<string, string>;
          const requiredErrors = validateAllRequired(PAYMENT_FIELDS, values);
          // @ts-ignore
          const isCardValid = validateCardNumber(values["cardNumber"]);
          const isDateValid = validateDate(
            // @ts-ignore
            values["expiryMonth"],
            // @ts-ignore
            values["expiryYear"]
          );
          const allErrors = { ...requiredErrors, ...errors };
          const isValid = isObjectEmpty(allErrors);
          if (isValid && isCardValid && isDateValid) {
            setIsDisabled(false);
          } else {
            setIsDisabled(true);
          }
          return errors;
        }}
        onSubmit={() => {}}
      >
        {({ errors, values }) => (
          <Form>
            <div className="card-form-header">
              <h3 className="section-header">Card Details</h3>
              <div>
                <img src={cardImgUrl} />
              </div>
            </div>
            <div>
              {Object.keys(cardFormData).map((value: string, index) => {
                if (!SPECIAL_CARD_FIELDS.includes(value)) {
                  // @ts-ignore
                  const fieldItem = cardFormData[value];

                  // @ts-ignore
                  const fieldError = errors[value];
                  // @ts-ignore
                  const fieldMessage = fieldItem["message"];
                  return (
                    <>
                      <FormFields
                        key={`${value} ${index}`}
                        fieldName={value}
                        field={fieldItem}
                      />
                      {fieldMessage && <FormMessage message={fieldMessage} />}
                      {fieldError && <FormError errorMessage={fieldError} />}
                    </>
                  );
                }
              })}
              <div className="card-details-section">
                <FormFields
                  key={`expiryMonth`}
                  fieldName={"expiryMonth"}
                  field={cardFormData["expiryMonth"]}
                />
                <FormFields
                  key={`expiryYear`}
                  fieldName={"expiryYear"}
                  field={cardFormData["expiryYear"]}
                />
                <FormFields
                  key={`ccv`}
                  fieldName={"ccv"}
                  field={cardFormData["ccv"]}
                />
              </div>
              <CtaButton
                title={"Pay"}
                isDisabled={isDisabled}
                onClick={() => handleSubmit(values)}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const validateCardNumber = (cardNumber: string | undefined): boolean => {
  if (cardNumber === undefined) {
    return false;
  }

  // Regular expression to check if the card number is exactly 16 digits
  const regex = /^\d{16}$/;

  // Test the card number against the regular expression
  return regex.test(cardNumber);
};

export const validateDate = (
  expiryMonth: string | undefined | null,
  expiryYear: string | undefined | null
): boolean => {
  if (
    expiryMonth === undefined ||
    expiryMonth === null ||
    expiryYear === undefined ||
    expiryYear === null
  ) {
    return false; // Handle cases where either month or year is undefined or null
  }

  // Convert to numbers
  const month = parseInt(expiryMonth, 10);
  const year = parseInt(expiryYear, 10);

  // Validate if both month and year are valid numbers
  if (isNaN(month) || isNaN(year)) {
    return false; // Handle cases where month or year are not valid numbers
  }

  // Get the current year and month
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Month is zero-indexed, so add 1

  // Check if expiryYear is a future year
  if (year < currentYear) {
    return false;
  }

  // If the expiryYear is the current year, check if expiryMonth is valid
  if (year === currentYear && month < currentMonth) {
    return false;
  }

  // Check if expiryMonth is between 1 and 12
  if (month < 1 || month > 12) {
    return false;
  }

  // If all checks pass, return true
  return true;
};

// Usage examples
