import { CouponData } from "../../../../../models/cart";
import { COUPON_DISCOUNT_TYPES } from "../../../checkout.consts";
import { getAppliedCouponsContent } from "./getAppliedCouponsContent";

interface CouponAppliedProps {
  coupons: CouponData[];
}
export const CouponsApplied = ({ coupons }: CouponAppliedProps) => {
  if (!coupons) {
    return null;
  }
  return (
    <>
      {coupons.map((coupon) => {
        const { couponCode, discountValue, couponDetails  } =
          getAppliedCouponsContent(coupon);
        return (
          <tr className="coupons-applied">
            <td>
              <strong>Coupon Applied</strong>
            </td>
            <td className="coupon-info">
              <span className="coupon-details">Coupon: "{couponCode}"</span>
              <span className="coupon-details">Discount: {discountValue}</span>
              {coupon.discountType !== COUPON_DISCOUNT_TYPES.FIXED_CART  ? <span className="coupon-details">Total Discounted Amount: ${Number(coupon.totalDiscount).toFixed(2)}</span> : null }
              <span className="coupon-description">{couponDetails}</span>
            </td>
          </tr>
        );
      })}
    </>
  );
};
