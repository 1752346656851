import { ReactNode, useState } from "react";

interface ToolTipProps {
  isTooltipHidden: boolean;
  content: JSX.Element;
  children: ReactNode;
}

export const Tooltip = ({
  isTooltipHidden,
  content,
  children,
}: ToolTipProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => {
    if (!isTooltipHidden) {
      setIsVisible(true);
    }
  };
  const hideTooltip = () => setIsVisible(false);

  return (
    <div
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      style={{ position: "relative", display: "inline-block" }}
    >
      {children}
      {isVisible && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "black",
            color: "white",
            padding: "5px",
            borderRadius: "5px",
            zIndex: 999,
            top: "100%",
            left: "50%",
            width: "160px",
            transform: "translateY(-50%)",
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
};
