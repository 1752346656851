import { Formik, Form } from "formik";
import { useCheckoutFormFields } from "./useCheckoutFormFields";
import { FormattedCartItem } from "../../../../models/cart";
import { Product } from "../../../../models/products/products.types";
import { FormFields } from "../../../../components/form-fields/FormFields";
import { SiteLocation } from "../../checkout.types";
import { useSaveForm } from "./useSaveForm";
import { isObjectEmpty } from "../../../../utils/isObjectEmpty";
import {
  phoneNumberValidation,
  postCodeValidation,
  validateAllRequired,
  validateRego,
} from "../../../../utils/form/validation";
import { FormError } from "../../../../components/form-fields/form-error/FormError";
import { FormMessage } from "../../../../components/form-fields/form-message/formMessage";

interface CheckoutFormProps {
  cartItems: FormattedCartItem[];
  products: Product[];
  locations: SiteLocation[];
}
export const CheckoutForm = ({
  cartItems,
  products,
  locations,
}: CheckoutFormProps) => {
  const { checkoutFormFields, initialValues, saveFormData } =
    useCheckoutFormFields(cartItems, products, locations);
  const { handleSaveForm, saveFormValidity, saveErroredFields } = useSaveForm();

  return (
    <div id="customer_details">
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validateOnMount
        validate={(values) => {
          const errors = {} as Record<string, string>;
          const requiredErrors = validateAllRequired(
            checkoutFormFields,
            values
          );

          if (
            values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (values.mobile && !phoneNumberValidation(values.mobile)) {
            errors.mobile =
              "Please ensure your mobile is only 10-digits, starting with 0";
          }
          if (
            values.vehicleRegistration &&
            !validateRego(values.vehicleRegistration)
          ) {
            errors.vehicleRegistration =
              "Please provide a valid vehicle registration - or enter TUNNEL if you are experiencing problems";
          }

          if (values.postCode && !postCodeValidation(values.postCode)) {
            errors.postCode = "Please provide a valid postcode";
          }
          const allErrors = { ...requiredErrors, ...errors };
          saveErroredFields(allErrors);
          const isValid = isObjectEmpty(allErrors);
          if (isValid) {
            handleSaveForm(values);
            saveFormData(values);
          }
          saveFormValidity(isValid);
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ errors }) => (
          <Form>
            <h3 className="section-header">Billing Details</h3>
            {Object.keys(checkoutFormFields).map((value: string, index) => {
              // @ts-ignore
              const fieldItem = checkoutFormFields[value];
              const fieldError = errors[value];
              // @ts-ignore
              const fieldMessage = fieldItem["message"];
              return (
                <>
                  <FormFields
                    key={`${value} ${index}`}
                    fieldName={value}
                    field={fieldItem}
                  />
                  {fieldMessage && <FormMessage message={fieldMessage} />}
                  {fieldError && <FormError errorMessage={fieldError} />}
                </>
              );
            })}
          </Form>
        )}
      </Formik>
    </div>
  );
};
