const T_C_MESSAGE = "I agree to the ";
export const TermsAndConditions = () => {
  return (
    <div className="checkout-t-and-c">
      {T_C_MESSAGE}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://localhost/tunnel-express/terms-and-conditions/#terms-club-edge"
      >
        Terms & Conditions
      </a>
    </div>
  );
};
