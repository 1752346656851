import { Field, ErrorMessage } from "formik";
import { REQUIRED } from "../formFields.consts";

interface TextAreaInputProps {
  fieldName: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
}

export const FormTextAreaInput = ({
  label,
  fieldName,
  required,
  placeholder = "",
}: TextAreaInputProps) => {
  return (
    <div className="form-field-container">
      <label className="form-field-label">
        {label ?? fieldName}
        {required ? (
          <span className="form-field-required"> {REQUIRED}</span>
        ) : (
          ""
        )}
      </label>
      <span className="woocommerce-input-wrapper">
        <Field
          as="textarea" // Render as textarea
          name={fieldName}
          className="textarea form-control" // Adjust class for textarea styling
          placeholder={placeholder} // Pass placeholder if provided
        />
      </span>
      <ErrorMessage name={fieldName} component="div" />
    </div>
  );
};
