export const getPriceString = (
  price: number,
  membershipDuration: number | null
) => {
  const total = price.toFixed(2);
  if (membershipDuration !== null) {
    if (membershipDuration !== 1) {
      return `${total} / ${membershipDuration} Months`;
    }
    return `$${total} / Month`;
  }
  return `$${total}`;
};
